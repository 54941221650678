import { salesLeadTypes } from "./sales-lead.types"

const INITIAL_VALUES = {
    sales_leads: [],
    sales_lead: {},
    admin_business_sales_leads: [],
    loading: false
}

const salesLeadsReducer = (state = INITIAL_VALUES, { type, payload }) => {
    switch (type) {
        case salesLeadTypes.FETCH_A_SALE_LEADS_PROCESS:
            return { ...state, sales_lead: [], loading: true }
        case salesLeadTypes.FETCH_A_SALE_LEADS_SUCCESS:
            return { ...state, sales_lead: payload, loading: false }

        case salesLeadTypes.FETCH_SALES_LEADS_PROCESS:
            return { ...state, sales_leads: [], loading: true }
        case salesLeadTypes.FETCH_SALES_LEADS_SUCCESS:
            return { ...state, sales_leads: payload, loading: false }

        case salesLeadTypes.FETCH_ADMIN_BUSINESS_SALES_LEAD_PROCESS:
            return { ...state, admin_business_sales_leads: [], loading: true }
        case salesLeadTypes.FETCH_ADMIN_BUSINESS_SALES_LEAD_SUCCESS:
            return { ...state, admin_business_sales_leads: payload, loading: false }
        default:
            return state
    }
}

export default salesLeadsReducer