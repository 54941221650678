import { Redirect } from "react-router";


export const GuestGuard = ({ children }) => {
    const user_token = localStorage.getItem("auth_token");
    if (user_token) {
        return <Redirect to='/admin/dashboard' />
    }
    return <div>{children}</div>;
};

export const AuthGuard = ({ children }) => {
    const user_token = localStorage.getItem("auth_token");
    if (!user_token) {
        if (window.location.pathname !== "/") {
            const redirectUrl = window.location.pathname;
            return <Redirect to={'/login?redirect=' + redirectUrl} />
        }
        return <Redirect to='/login' />
    }
    return <div>{children}</div>;
};

