export const BusinessTypes = {
    FETCH_BUSINESS_CATEGORY_PROCESS: 'FETCH_BUSINESS_CATEGORY_PROCESS',
    FETCH_BUSINESS_CATEGORY_SUCCESS: 'FETCH_BUSINESS_CATEGORY_SUCCESS',

    FETCH_SINGLE_BUSINESS_CATEGORY_PROCESS: 'FETCH_SINGLE_BUSINESS_CATEGORY_PROCESS',
    FETCH_SINGLE_BUSINESS_CATEGORY_SUCCESS: 'FETCH_SINGLE_BUSINESS_CATEGORY_SUCCESS',

    FETCH_BUSINESS_SUB_CATEGORY_PROCESS: 'FETCH_BUSINESS_SUB_CATEGORY_PROCESS',
    FETCH_BUSINESS_SUB_CATEGORY_SUCCESS: 'FETCH_BUSINESS_SUB_CATEGORY_SUCCESS',

    FETCH_SINGLE_SUB_BUSINESS_CATEGORY_PROCESS: 'FETCH_SINGLE_BUSINESS_SUB_CATEGORY_PROCESS',
    FETCH_SINGLE_SUB_BUSINESS_CATEGORY_SUCCESS: 'FETCH_SINGLE_BUSINESS_SUB_CATEGORY_SUCCESS',

    FETCH_STAT_PROCESS: 'FETCH_STAT_PROCESS',
    FETCH_STAT_SUCCESS: 'FETCH_STAT_SUCCESS',

    FETCH_BUSINESS_LOCATION_PROCESS: 'FETCH_BUSINESS_LOCATION_PROCESS',
    FETCH_BUSINESS_LOCATION_SUCCESS: 'FETCH_BUSINESS_LOCATION_SUCCESS',

    FETCH_LOCAL_GOVERNMENT_PROCESS: 'FETCH_LOCAL_GOVERNMENT_PROCESS',
    FETCH_LOCAL_GOVERNMENT_SUCCESS: 'FETCH_LOCAL_GOVERNMENT_SUCCESS',

    FETCH_CITY_PROCESS: 'FETCH_CITY_PROCESS',
    FETCH_CITY_SUCCESS: 'FETCH_CITY_SUCCESS',

    FETCH_KYC_DOCUMENT_PROCESS: 'FETCH_KYC_DOCUMENT_PROCESS',
    FETCH_KYC_DOCUMENT_SUCCESS: 'FETCH_KYC_DOCUMENT_SUCCESS',

    FETCH_OWNERS_PROCESS: 'FETCH_OWNERS_PROCESS',
    FETCH_OWNERS_SUCCESS: 'FETCH_OWNERS_SUCCESS',

    FETCH_A_BUSINESS_PROCESS: 'FETCH_A_BUSINESS_PROCESS',
    FETCH_A_BUSINESS_SUCCESS: 'FETCH_A_BUSINESS_SUCCESS',

    FETCH_ALL_BUSINESS_PROCESS: 'FETCH_ALL_BUSINESS_PROCESS',
    FETCH_ALL_BUSINESS_SUCCESS: 'FETCH_ALL_BUSINESS_SUCCESS',
    FETCH_ALL_BUSINESS_RAW_SUCCESS: 'FETCH_ALL_BUSINESS_RAW_SUCCESS',

    FETCH_STATE_PROCESS: 'FETCH_STATE_PROCESS',
    FETCH_STATE_SUCCESS: 'FETCH_STATE_SUCCESS',

    FETCH_ALL_BUSINESS_CATEGORY_PROCESS: 'FETCH_ALL_BUSINESS_CATEGORY_PROCESS',
    FETCH_ALL_BUSINESS_CATEGORY_SUCCESS: 'FETCH_ALL_BUSINESS_CATEGORY_SUCCESS',

    FETCH_ALL_BUSINESS_SUBCATEGORY_PROCESS: 'FETCH_ALL_BUSINESS_SUBCATEGORY_PROCESS',
    FETCH_ALL_BUSINESS_SUBCATEGORY_SUCCESS: 'FETCH_ALL_BUSINESS_SUBCATEGORY_SUCCESS',

    FETCH_ALL_BUSINESS_TYPES_PROCESS: 'FETCH_AL_BUSINESS_TYPES_PROCESS',
    FETCH_ALL_BUSINESS_TYPES_SUCCESS: 'FETCH_AL_BUSINESS_TYPES_SUCCESS',

    FETCH_BUSINESS_HOURS_PROCESS: 'FETCH_BUSINESS_HOURS_PROCESS',
    FETCH_BUSINESS_HOURS_SUCCESS: 'FETCH_BUSINESS_HOURS_SUCCESS',

    FETCH_BUSINESS_HEALTH_PROCESS: 'FETCH_BUSINESS_HEALTH_PROCESS',
    FETCH_BUSINESS_HEALTH_SUCCESS: 'FETCH_BUSINESS_HEALTH_SUCCESS'
}