import { ListingTypes } from "./listings.types";
const initialState = {
  listings: [],
  loading: false,
  listing_categories: [],
  listing_category: {},
  listing_category_properties: [],
  listing_category_property: {},
  property_values: [],
  property_value: {},
  property_extras: [],
  property_extra: {},
  property_extra_values: [],
  property_extra_value: {},
  listing_sub_category: {},
  listing_sub_categories: [],
  sub_categories_properties: [],
  sub_category_property: {},
  sub_category_property_values: [],
  sub_category_property_value: {},
  data_types: []
  // listingDetails: {}
}

const modelListings = (listings) => {
  return listings.map(listing => {
    return {
      label: listing.name,
      value: listing.id
    }
  })
}

const listingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ListingTypes.FETCH_LISTINGS_PROCESS:
      return {
        ...state
      }
    case ListingTypes.FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: action.payload,
        loading: false,
      }
    case ListingTypes.FETCH_SINGLE_LISTING:
      return {
        ...state,
        listingDetails: action.payload
      }
    case ListingTypes.ADD_LISTING_SUCCESS:
      return {
        ...state,
        listingDetails: action.payload
      }
    case ListingTypes.FETCH_LISTING_CATEGORY_PROCESS:
      return { ...state, listing_categories: [], loading: true }
    case ListingTypes.FETCH_LISTING_CATEGORY_SUCCESS:
      return { ...state, listing_categories: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_SUCCESS:
      return { ...state, listing_category: action.payload, loading: false }
      
    case ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROCESS:
      return { ...state, listing_category: {}, loading: true }

    case ListingTypes.FETCH_LISTING_CATEGORY_PROPERTY_PROCESS:
      return { ...state, listing_category_properties: [], loading: true }
    case ListingTypes.FETCH_LISTING_CATEGORY_PROPERTY_SUCCESS:
      return { ...state, listing_category_properties: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_PROCESS:
      return { ...state, listing_category_property: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_SUCCESS:
      return { ...state, listing_category_property: action.payload, loading: false }

    case ListingTypes.FETCH_PROPERTY_VALUES_PROCESS:
      return { ...state, property_values: [], loading: true }
    case ListingTypes.FETCH_PROPERTY_VALUES_SUCCESS:
      return { ...state, property_values: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_PROPERTY_VALUES_PROCESS:
      return { ...state, property_value: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_PROPERTY_VALUES_SUCCESS:
      return { ...state, property_value: action.payload, loading: false }

    case ListingTypes.FETCH_PROPERTY_EXTRAS_PROCESS:
      return { ...state, property_extras: [], loading: true }
    case ListingTypes.FETCH_PROPERTY_EXTRAS_SUCCESS:
      return { ...state, property_extras: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_PROCESS:
      return { ...state, property_extra: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_SUCCESS:
      return { ...state, property_extra: action.payload, loading: false }

    case ListingTypes.FETCH_PROPERTY_EXTRAS_VALUES_PROCESS:
      return { ...state, property_extra_values: [], loading: true }
    case ListingTypes.FETCH_PROPERTY_EXTRAS_VALUES_SUCCESS:
      return { ...state, property_extra_values: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_PROCESS:
      return { ...state, property_extra_value: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_SUCCESS:
      return { ...state, property_extra_value: action.payload, loading: false }

    case ListingTypes.FETCH_LISTING_SUBCATEGORY_PROCESS:
      return { ...state, listing_sub_categories: [], loading: true }
    case ListingTypes.FETCH_LISTING_SUBCATEGORY_SUCCESS:
      return { ...state, listing_sub_categories: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROCESS:
      return { ...state, listing_sub_category: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_SUCCESS:
      return { ...state, listing_sub_category: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_PROCESS:
      return { ...state, sub_category_property: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_SUCCESS:
      return { ...state, sub_category_property: action.payload, loading: false }

    case ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_PROCESS:
      return { ...state, sub_categories_properties: [], loading: true }
    case ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_SUCCESS:
      return { ...state, sub_categories_properties: action.payload, loading: false }

    case ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS:
      return { ...state, sub_category_property_values: [], loading: true }
    case ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS:
      return { ...state, sub_category_property_values: action.payload, loading: false }

    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS:
      return { ...state, sub_category_property_value: {}, loading: true }
    case ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS:
      return { ...state, sub_category_property_value: action.payload, loading: false }

    case ListingTypes.FETCH_ALL_LISTINGS_PROCESS:
      return { ...state, listings: [], loading: true }
    case ListingTypes.FETCH_ALL_LISTINGS_SUCCESS:
      return { ...state, listings: modelListings(action.payload?.data), loading: false }

    case ListingTypes.FETCH_ALL_DATA_TYPES_PROCESS:
      return { ...state, data_types: [], loading: true }
    case ListingTypes.FETCH_ALL_DATA_TYPES_SUCCESS:
      return { ...state, data_types: action?.payload, loading: false }
    default:
      return state;
  }
}

export default listingsReducer;