import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes, routes } from './routes';
import "./assets/styles/index.css"

const App = ({ history }) => {
    return (
        <Router history={history}>
            {renderRoutes(routes)}
        </Router>
    );
}
export default App;