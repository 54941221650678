import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import "./assets/styles/main.css";
import SimpleReactLightbox from 'simple-react-lightbox'

import { createBrowserHistory } from 'history'

import App from "./App";

const history = createBrowserHistory();
ReactDOM.render(
  <Provider store={store}>
    <SimpleReactLightbox>
      <App history={history} />
    </SimpleReactLightbox>
  </Provider>,
  document.getElementById("root")
);
