import { ClubConnectTypes } from "./club-connect.types"

const INITIAL_STATE = {
    subscription_plans: [],
    subscription_plan: {},
    courses: [],
    course: {},
    loading: false,
    articles: [],
    cac: [],
    bizmixs: [],
    bixmix: {},
    merchants: [],
    merchant: {},
    merchant_products: [],
    merchant_product: {}
}

const clubConnectReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ClubConnectTypes.FETCH_CLUB_CONNECT_SUBSCRIPTION_PROCESS:
            return { ...state, subscription_plans: [], loading: true }
        case ClubConnectTypes.FETCH_CLUB_CONNECT_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription_plans: payload, loading: false }

        case ClubConnectTypes.FETCH_A_CLUB_CONNECT_SUBSCRIPTION_PROCESS:
            return { ...state, subscription_plan: {}, loading: true }
        case ClubConnectTypes.FETCH_A_CLUB_CONNECT_SUBSCRIPTION_SUCCESS:
            return { ...state, subscription_plan: payload, loading: false }

        case ClubConnectTypes.FETCH_CLUB_CONNECT_COURSES_PROCESS:
            return { ...state, courses: [], loading: true }
        case ClubConnectTypes.FETCH_CLUB_CONNECT_COURSES_SUCCESS:
            return { ...state, courses: payload, loading: false }

        case ClubConnectTypes.FETCH_A_CLUB_CONNECT_COURSES_PROCESS:
            return { ...state, course: {}, loading: true }
        case ClubConnectTypes.FETCH_A_CLUB_CONNECT_COURSES_SUCCESS:
            return { ...state, course: payload, loading: false }

        case ClubConnectTypes.FETCH_CLUB_CONNECT_ARTICLE_PROCESS:
            return { ...state, articles: [], loading: true }
        case ClubConnectTypes.FETCH_CLUB_CONNECT_ARTICLE_SUCCESS:
            return { ...state, articles: payload, loading: false }

        case ClubConnectTypes.FETCH_CLUB_CONNECT_CAC_PROCESS:
            return { ...state, cac: [], loading: true }
        case ClubConnectTypes.FETCH_CLUB_CONNECT_CAC_SUCCESS:
            return { ...state, cac: payload, loading: false }

        case ClubConnectTypes.FETCH_ALL_BIXMIX_PROCESS:
            return { ...state, bizmixs: [], loading: true }
        case ClubConnectTypes.FETCH_ALL_BIXMIX_SUCCESS:
            return { ...state, bizmixs: payload, loading: false }

        case ClubConnectTypes.FETCH_BIXMIX_PROCESS:
            return { ...state, bizmix: [], loading: true }
        case ClubConnectTypes.FETCH_BIXMIX_SUCCESS:
            return { ...state, bizmix: payload, loading: false }

        case ClubConnectTypes.FETCH_MERCHANT_PROCESS:
            return { ...state, merchant: {}, loading: true }
        case ClubConnectTypes.FETCH_MERCHANT_SUCCESS:
            return { ...state, merchant: payload, loading: false }

        case ClubConnectTypes.FETCH_ALL_MERCHANT_PROCESS:
            return { ...state, merchants: [], loading: true }
        case ClubConnectTypes.FETCH_ALL_MERCHANT_SUCCESS:
            return { ...state, merchants: payload, loading: false }

            case ClubConnectTypes.FETCH_MERCHANT_PRODUCT_PROCESS:
                return { ...state, merchant_product: {}, loading: true }
            case ClubConnectTypes.FETCH_MERCHANT_PRODUCT_SUCCESS:
                return { ...state, merchant_product: payload, loading: false }
    
            case ClubConnectTypes.FETCH_ALL_MERCHANT_PRODUCT_PROCESS:
                return { ...state, merchant_products: [], loading: true }
            case ClubConnectTypes.FETCH_ALL_MERCHANT_PRODUCT_SUCCESS:
                return { ...state, merchant_products: payload, loading: false }

        default:
            return state
    }
}

export default clubConnectReducer