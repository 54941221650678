export const DashboardConstants = {
  DASHBOARD_STATS_SUCCESS: "DASHBOARD_STATS_SUCCESS",
  BUSINESS_LIST_SUCCESS: "BUSINESS_LIST_SUCCESS",
  REMOVE_ONE_BUSINESS_SUCCESS:"REMOVE_ONE_BUSINESS_SUCCESS",
  RESTORE_ONE_BUSINESS_SUCCESS:"RESTORE_ONE_BUSINESS_SUCCESS",
  LISTING_CATEGORIES_SUCCESS: "LISTING_CATEGORIES_SUCCESS",
  CATEGORY_PROPERTIES_SUCCESS: "CATEGORY_PROPERTIES_SUCCES",
  SUB_CATEGORIES_SUCCESS: "SUB_CATEGORIES_SUCCESS",
  SUB_CATEGORIES_PROPERTIES_SUCCESS: 'SUB_CATEGORIES_PROPERTIES_SUCCESS',
  CATEGORIES_PROPERTIES_EXTRA_VALUES :'CATEGORIES_PROPERTIES_EXTRA_VALUES',
  ADMIN_LIST_SUCCESS:"ADMIN_LIST_SUCCESS",

  FETCH_USER_CONSTANT_SUCCESS: 'FETCH_USER_CONSTANT_SUCCESS',
  FETCH_USER_CONSTANT_PROCESS: 'FETCH_USER_CONSTANT_PROCESS',

  FETCH_FEATURED_MODEL_CONSTANT_SUCCESS: 'FETCH_FEATURED_MODEL_CONSTANT_SUCCESS',
  FETCH_FEATURED_MODEL_CONSTANT_PROCESS: 'FETCH_FEATURED_MODEL_CONSTANT_PROCESS',

  FETCH_ADVERT_SUBSCRIPTION_CONSTANT_SUCCESS: 'FETCH_ADVERT_SUBSCRIPTION_CONSTANT_SUCCESS',
  FETCH_ADVERT_SUBSCRIPTION_CONSTANT_PROCESS: 'FETCH_ADVERT_SUBSCRIPTION_CONSTANT_PROCESS',

  FETCH_RECENT_LISTINGS_CONSTANT_SUCCESS: 'FETCH_RECENT_LISTINGS_CONSTANT_SUCCESS',
  FETCH_RECENT_LISTINGS_CONSTANT_PROCESS: 'FETCH_RECENT_LISTINGS_CONSTANT_PROCESS',

  FETCH_RECENT_BUSINESSES_CONSTANT_SUCCESS: 'FETCH_RECENT_BUSINESSES_CONSTANT_SUCCESS',
  FETCH_RECENT_BUSINESSES_CONSTANT_PROCESS: 'FETCH_RECENT_BUSINESSES_CONSTANT_PROCESS',

  FETCH_TOTAL_ADMIN_USER_PROCESS: 'FETCH_TOTAL_ADMIN_USER_PROCESS',
  FETCH_TOTAL_ADMIN_USER_SUCCESS: 'FETCH_TOTAL_ADMIN_USER_SUCCESS',

  FETCH_TOTAL_USER_PROCCESS: 'FETCH_TOTAL_USER_PROCCESS',
  FETCH_TOTAL_USER_SUCCESS: 'FETCH_TOTAL_USER_SUCCESS'
};
