export const getToken = () => localStorage.getItem('auth_token');

export const deleteToken = () => localStorage.removeItem('auth_token');

/**
 * Convert objects to CSV format
 * @param {Object} data
 * @returns {String} CSV format
 * @example
 * const data = [
 *  {name: 'John', age: 30},
 *  {name: 'Jane', age: 25},
 *  {name: 'Jack', age: 20},
 * ];
 * const csv = objectToCSV(data);
 * console.log(csv);
 * // name,age
 * // John,30
 * // Jane,25
 * // Jack,20
 **/
export const objectToCSV = (data) => {
  if (data.length === 0) {
    return '';
  }

  const csvRows = [];

  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
}

/**
 * Download objects as CSV file
 * @param {Object} data
 * @param {String} filename
 * @example
 * const data = [
 * {name: 'John', age: 30},
 * {name: 'Jane', age: 25},
 * {name: 'Jack', age: 20},
 * ];
 * downloadCSV(data, 'users.csv');
 * // Download users.csv file
 **/
export const downloadCSV = (data, filename) => {
  const csvData = objectToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * Truncate string
 * @param {String} str
 * @param {Number} length
 * @param {String} ending - default: '...'
 * @returns {String} truncated string
 * @example
 * truncate('Lorem ipsum dolor sit amet', 10);
 * // 'Lorem ipsu...'
 * truncate('Lorem ipsum dolor sit amet', 10, '---');
 * // 'Lorem ipsu---'
 * truncate('Lorem ipsum dolor sit amet', 20, '');
 * // 'Lorem ipsum dolor si'
 **/
export const truncate = (str, length, ending = '...') => {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  }
  return str;
}