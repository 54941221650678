import { UserTypes } from "./users.types";

const initialState ={
  users:[]
}

const bizmixUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.FETCH_BIZMIX_USERS_SUCCESS:
      return {
        ...state,
       users: action.payload,
      }
      default:
        return state;
  }
  
}

export default bizmixUsersReducer
