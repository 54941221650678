import { DashboardConstants } from "../constants/AdminConstants";

const initialState = {
  dashboardStats: {},
  userStat: {},
  featuredModelStat: {},
  advertSubscriptionStat: {},
  recentBusinesses: [],
  recentListings: [],
  totalAdmin: 0,
  totalUser: 0,
};

const dashboardStats = (state = initialState, action) => {
  switch (action.type) {
    case DashboardConstants.DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboardStats: action.payload,
      };
    case DashboardConstants.BUSINESS_LIST_SUCCESS:
      return {
        ...state,
        dashboardBusinessList: action.payload
      }
    case DashboardConstants.ADMIN_LIST_SUCCESS:
      return {
        ...state,
        dashboardAdminList: action.payload
      }
    case DashboardConstants.REMOVE_ONE_ADMIN_SUCCESS:
      return {
        ...state,
      }
    case DashboardConstants.LISTING_CATEGORIES_SUCCESS:
      return {
        ...state,
        listingsCategories: action.payload
      }
    case DashboardConstants.CATEGORY_PROPERTIES_SUCCESS:
      return {
        ...state,
        categoryProperties: action.payload
      }
    case DashboardConstants.CATEGORIES_PROPERTIES_EXTRA_VALUES:
      return {
        ...state,
        extraValues: action.payload
      }
    case DashboardConstants.SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: action.payload
      }
    case DashboardConstants.SUB_CATEGORIES_PROPERTIES_SUCCESS:
      return {
        ...state,
        subCategoriesProperties: action.payload
      }

    case DashboardConstants.FETCH_USER_CONSTANT_PROCESS:
      return { ...state, userStat: {} }
    case DashboardConstants.FETCH_USER_CONSTANT_SUCCESS:
      return { ...state, userStat: action.payload }

    case DashboardConstants.FETCH_FEATURED_MODEL_CONSTANT_PROCESS:
      return { ...state, featuredModelStat: {} }
    case DashboardConstants.FETCH_FEATURED_MODEL_CONSTANT_SUCCESS:
      return { ...state, featuredModelStat: action.payload }

      case DashboardConstants.FETCH_ADVERT_SUBSCRIPTION_CONSTANT_PROCESS:
        return { ...state, advertSubscriptionStat: {} }
      case DashboardConstants.FETCH_ADVERT_SUBSCRIPTION_CONSTANT_SUCCESS:
        return { ...state, advertSubscriptionStat: action.payload }

    case DashboardConstants.FETCH_RECENT_BUSINESSES_CONSTANT_PROCESS:
      return { ...state, recentBusinesses: {} }
    case DashboardConstants.FETCH_RECENT_BUSINESSES_CONSTANT_SUCCESS:
      return { ...state, recentBusinesses: action.payload }

    case DashboardConstants.FETCH_RECENT_LISTINGS_CONSTANT_PROCESS:
      return { ...state, recentListings: {} }
    case DashboardConstants.FETCH_RECENT_LISTINGS_CONSTANT_SUCCESS:
      return { ...state, recentListings: action.payload }

    case DashboardConstants.FETCH_TOTAL_ADMIN_USER_PROCESS:
      return { ...state, totalAdmin: '' }
    case DashboardConstants.FETCH_TOTAL_ADMIN_USER_SUCCESS:
      return { ...state, totalAdmin: action.payload }

    case DashboardConstants.FETCH_TOTAL_USER_PROCCESS:
      return { ...state, totalUser: '' }
    case DashboardConstants.FETCH_TOTAL_USER_SUCCESS:
      return { ...state, totalUser: action.payload }
    default:
      return state;
  }
};

export default dashboardStats;
