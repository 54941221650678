import { ClaimBusinessTypes } from "./claim-business.types"

const INITIAL_STATE = {
    claim_business_request: [],
    loading: false
}

const claimBusinessReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case ClaimBusinessTypes.FETCH_CONNECTION_REQUEST_PROCESS:
            return { ...state, loading: true, claim_business_request: [] };
        case ClaimBusinessTypes.FETCH_CONNECTION_REQUEST_SUCCESS:
            return { ...state, loading: false, claim_business_request: payload };
        default:
            return state;
    }
}

export default claimBusinessReducer