import React, { useEffect } from "react";
import axios from "axios";
import { deleteToken, getToken } from "./helper";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { Redirect } from "react-router";

const logout = () => {
  deleteToken();
  const redirectUrl = window.location.pathname;
  return (window.location.href = "/login?redirect=" + redirectUrl);
};

const ConnectNigeria = axios.create();

ConnectNigeria.interceptors.request.use(
  (config) => {
    config.baseURL = process.env.REACT_APP_API_HOST;
    config.headers["Authorization"] = getToken() && `Bearer ${getToken()}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
ConnectNigeria.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.error('error_status', error.response.status);
    if (error?.response?.status === 401) {
      Swal.fire({
        title: "Session Expired",
        showCancelButton: true,
        preConfirm: logout,
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
    return Promise.reject(error);
  }
);

export default ConnectNigeria;
