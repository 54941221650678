export const StateTypes = {
    FETCH_ALL_STATE_PROCESS: 'FETCH_ALL_STATE_PROCESS',
    FETCH_ALL_STATE_SUCCESS: 'FETCH_ALL_STATE_SUCCESS',

    FETCH_ALL_CITIES_PROCESS: 'FETCH_ALL_CITIES_PROCESS',
    FETCH_ALL_CITIES_SUCCESS: 'FETCH_ALL_CITIES_SUCCESS',

    FETCH_A_CITY_PROCESS: 'FETCH_A_CITY_PROCESS',
    FETCH_A_CITY_SUCCESS: 'FETCH_A_CITY_SUCCESS',

    FETCH_ALL_LOCAL_GOVERNMENT_PROCESS: 'FETCH_ALL_LOCAL_GOVERNMENT_PROCESS',
    FETCH_ALL_LOCAL_GOVERNMENT_SUCCESS: 'FETCH_ALL_LOCAL_GOVERNMENT_SUCCESS',

    FETCH_A_LOCAL_GOVERNMENT_PROCESS: 'FETCH_A_LOCAL_GOVERNMENT_PROCESS',
    FETCH_A_LOCAL_GOVERNMENT_SUCCESS: 'FETCH_A_LOCAL_GOVERNMENT_SUCCESS'
}