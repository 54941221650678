export const ListingTypes = {
  FETCH_LISTINGS_PROCESS: 'FETCH_LISTINGS_PROCESS',
  FETCH_LISTINGS_SUCCESS: 'FETCH_LISTINGS_SUCCESS',

  FETCH_LISTINGS_ERROR: 'FETCH_LISTINGS_ERROR',
  ADD_LISTING_SUCCESS: 'ADD_LISTING_SUCCESS',
  FETCH_SINGLE_LISTING: 'FETCH_SINGLE_LISTING',
  FETCH_LISTING_CATEGORY_PROCESS: 'FETCH_LISTING_CATEGORY_PROCESS',
  FETCH_LISTING_CATEGORY_SUCCESS: 'FETCH_LISTING_CATEGORY_SUCCESS',

  FETCH_SINGLE_LISTING_CATEGORY_PROCESS: 'FETCH_SINGLE_LISTING_CATEGORY_PROCESS',
  FETCH_SINGLE_LISTING_CATEGORY_SUCCESS: 'FETCH_SINGLE_LISTING_CATEGORY_SUCCESS',

  UPDATE_LISTING_CATEGORY_PROCESS: 'UPDATE_LISTING_CATEGORY_PROCESS',
  UPDATE_LISTING_CATEGORY_SUCCESS: 'UPDATE_LISTING_CATEGORY_SUCCESS',

  RESTORE_LISTING_CATEGORY_PROCESS: 'RESTORE_LISTING_CATEGORY_PROCESS',
  RESTORE_LISTING_CATEGORY_SUCCESS: 'RESTORE_LISTING_CATEGORY_SUCCESS',

  FETCH_LISTING_CATEGORY_PROPERTY_PROCESS: 'FETCH_LISTING_CATEGORY_PROPERTY_PROCESS',
  FETCH_LISTING_CATEGORY_PROPERTY_SUCCESS: 'FETCH_LISTING_CATEGORY_PROPERTY_SUCCESS',

  FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_PROCESS: 'FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_PROCESS',
  FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_SUCCESS: 'FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_SUCCESS',

  FETCH_PROPERTY_VALUES_PROCESS: 'FETCH_PROPERTY_VALUES_PROCESS',
  FETCH_PROPERTY_VALUES_SUCCESS: 'FETCH_PROPERTY_VALUES_SUCCESS',

  FETCH_SINGLE_PROPERTY_VALUES_PROCESS: 'FETCH_SINGLE_PROPERTY_VALUES_PROCESS',
  FETCH_SINGLE_PROPERTY_VALUES_SUCCESS: 'FETCH_SINGLE_PROPERTY_VALUES_SUCCESS',

  FETCH_PROPERTY_EXTRAS_PROCESS: 'FETCH_PROPERTY_EXTRAS_PROCESS',
  FETCH_PROPERTY_EXTRAS_SUCCESS: 'FETCH_PROPERTY_EXTRAS_SUCCESS',

  FETCH_SINGLE_PROPERTY_EXTRAS_PROCESS: 'FETCH_SINGLE_PROPERTY_EXTRAS_PROCESS',
  FETCH_SINGLE_PROPERTY_EXTRAS_SUCCESS: 'FETCH_SINGLE_PROPERTY_EXTRAS_SUCCESS',

  FETCH_PROPERTY_EXTRAS_VALUES_PROCESS: 'FETCH_PROPERTY_EXTRAS_VALUES_PROCESS',
  FETCH_PROPERTY_EXTRAS_VALUES_SUCCESS: 'FETCH_PROPERTY_EXTRAS_VALUES_SUCCESS',

  FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_PROCESS: 'FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_PROCESS',
  FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_SUCCESS: 'FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_SUCCESS',

  FETCH_LISTING_SUBCATEGORY_PROCESS: 'FETCH_LISTING_SUBCATEGORY_PROCESS',
  FETCH_LISTING_SUBCATEGORY_SUCCESS: 'FETCH_LISTING_SUBCATEGORY_SUCCESS',

  FETCH_SINGLE_LISTING_SUBCATEGORY_PROCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_PROCESS',
  FETCH_SINGLE_LISTING_SUBCATEGORY_SUCCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_SUCCESS',

  FETCH_LISTING_SUBCATEGORY_PROPERTY_PROCESS: 'FETCH_LISTING_SUBCATEGORY_PROPERTY_PROCESS',
  FETCH_LISTING_SUBCATEGORY_PROPERTY_SUCCESS: 'FETCH_LISTING_SUBCATEGORY_PROPERTY_SUCCESS',

  FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_PROCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_PROCESS',
  FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_SUCCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_SUCCESS',

  FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS: 'FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS',
  FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS: 'FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS',

  FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS',
  FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS: 'FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS',

  FETCH_ALL_LISTINGS_PROCESS: 'FETCH_ALL_LISTINGS_PROCESS',
  FETCH_ALL_LISTINGS_SUCCESS: 'FETCH_ALL_LISTINGS_SUCCESS',

  FETCH_ALL_DATA_TYPES_PROCESS: 'FETCH_ALL_DATA_TYPES_PROCESS',
  FETCH_ALL_DATA_TYPES_SUCCESS: 'FETCH_ALL_DATA_TYPES_SUCCESS'
}