import { NewletterTypes } from "./newsletter.types"

const initialState = {
    newsletters: [],
    loading: false
}

const newsLetterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case NewletterTypes.FETCH_NEWSLETTER_PROCESS:
            return { ...state, newsletters: [], loading: true }
        case NewletterTypes.FETCH_NEWSLETTER_SUCCESS:
            return { ...state, newsletters: payload, loading: false }
        default:
            return state;
    }
}

export default newsLetterReducer;