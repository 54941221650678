export const AdvertTypes = {
    FETCH_ALL_ADVERT_PACKAGE_PROCESS: 'FETCH_ALL_ADVERT_PACKAGE_PROCESS',
    FETCH_ALL_ADVERT_PACKAGE_SUCCESS: 'FETCH_ALL_ADVERT_PACKAGE_SUCCESS',

    FETCH_AN_ADVERT_PACKAGE_PROCESS: 'FETCH_AN_ADVERT_PACKAGE_PROCESS',
    FETCH_AN_ADVERT_PACKAGE_SUCCESS: 'FETCH_AN_ADVERT_PACKAGE_SUCCESS',

    FETCH_ALL_ADVERT_PLANS_PROCESS: 'FETCH_ALL_ADVERT_PLANS_PROCESS',
    FETCH_ALL_ADVERT_PLANS_SUCCESS: 'FETCH_ALL_ADVERT_PLANS_SUCCESS',

    FETCH_AN_ADVERT_PLAN_PROCESS: 'FETCH_AN_ADVERT_PLAN_PROCESS',
    FETCH_AN_ADVERT_PLAN_SUCCESS: 'FETCH_AN_ADVERT_PLAN_SUCCESS',

    FETCH_ALL_SUBSCRIPTION_PLAN_PROCESS: 'FETCH_ALL_SUBSCRIPTION_PLAN_PROCESS',
    FETCH_ALL_SUBSCRIPTION_PLAN_SUCCESS: 'FETCH_ALL_SUBSCRIPTION_PLAN_SUCCESS',

    FETCH_AN_SUBSCRIPTION_PLAN_PROCESS: 'FETCH_AN_SUBSCRIPTION_PLAN_PROCESS',
    FETCH_AN_SUBSCRIPTION_PLAN_SUCCESS: 'FETCH_AN_SUBSCRIPTION_PLAN_SUCCESS',

    FETCH_ALL_FEATURED_MODEL_PROCESS: 'FETCH_ALL_FEATURED_MODEL_PROCESS',
    FETCH_ALL_FEATURED_MODEL_SUCCESS: 'FETCH_ALL_FEATURED_MODEL_SUCCESS',

    FETCH_ALL_TRANSACTIONS_PROCESS: 'FETCH_ALL_TRANSACTIONS_PROCESS',
    FETCH_ALL_TRANSACTIONS_SUCCESS: 'FETCH_ALL_TRANSACTIONS_SUCCESS',

    FETCH_ALL_REALTIME_REVENUE_PROCESS: 'FETCH_ALL_REALTIME_REVENUE_PROCESS',
    FETCH_ALL_REALTIME_REVENUE_SUCCESS: 'FETCH_ALL_REALTIME_REVENUE_SUCCESS',

    FETCH_ALL_GMV_PROCESS: 'FETCH_ALL_GMV_PROCESS',
    FETCH_ALL_GMV_SUCCESS: 'FETCH_ALL_GMV_SUCCESS',

    FETCH_ALL_BTS_PROCESS: 'FETCH_ALL_BTS_PROCESS',
    FETCH_ALL_BTS_SUCCESS: 'FETCH_ALL_BTS_SUCCESS',

    FETCH_ALL_MARKET_BUDGETS_PROCESS: 'FETCH_ALL_MARKET_BUDGETS_PROCESS',
    FETCH_ALL_MARKET_BUDGETS_SUCCESS: 'FETCH_ALL_MARKET_BUDGETS_SUCCESS',

    FETCH_ALL_MONTHLY_CAC_PROCESS: 'FETCH_ALL_M ONTHLY_CA_PROCESS',
    FETCH_ALL_MONTHLY_CAC_SUCCESS: 'FETCH_ALL_M ONTHLY_CA_SUCCESS',
}

export const FeaturedSubscriptionPlanEnums = [
    {
        name: "business"
    },
    {
        name: "listing"
    }
]

export const TransactionPayment = [
    {
        model: 'business',
        package: 'featured_subscription_plan',
        purpose: 'featured_model'
    },
    {
        model: 'listing',
        package: 'featured_subscription_plan',
        purpose: 'featured_model'
    },
    {
        model: 'user',
        package: 'advert_package_plan',
        advert: 'advert'
    }
]