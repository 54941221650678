import React, { useEffect } from "react";
import { createStructuredSelector } from 'reselect'
import Navbar from "../components/Navbars/AuthNavbar.js";
import FooterSmall from "../components/Footers/FooterSmall.js";
import { connect } from "react-redux";
import { selectAError } from "../redux/alert/alert.selectors.js";
import Error from "../components/Alert/Error.js";
import { notify } from "../helpers.js";
import { toast } from "react-toastify";

const Auth = ({ children, error }) => {
  return (
    <>
      {/* <Navbar transparent /> */}
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">

          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("../assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
          {children}

          {/* <FooterSmall absolute /> */}
          <div className="absolute bottom-0 left-0">
            {error && toast.error(error)}
          </div>
        </section>
      </main>
    </>
  );
}


const mapStateToProps = createStructuredSelector({
  error: selectAError
})
export default connect(mapStateToProps, null)(Auth)