export const ClubConnectTypes = {
    FETCH_CLUB_CONNECT_SUBSCRIPTION_PROCESS: 'FETCH_CLUB_CONNECT_SUBSCRIPTION_PROCESS',
    FETCH_CLUB_CONNECT_SUBSCRIPTION_SUCCESS: 'FETCH_CLUB_CONNECT_SUBSCRIPTION_SUCCESS',

    FETCH_A_CLUB_CONNECT_SUBSCRIPTION_PROCESS: 'FETCH_A_CLUB_CONNECT_SUBSCRIPTION_PROCESS',
    FETCH_A_CLUB_CONNECT_SUBSCRIPTION_SUCCESS: 'FETCH_A_CLUB_CONNECT_SUBSCRIPTION_SUCCESS',

    FETCH_CLUB_CONNECT_COURSES_PROCESS: 'FETCH_CLUB_CONNECT_COURSES_PROCESS',
    FETCH_CLUB_CONNECT_COURSES_SUCCESS: 'FETCH_CLUB_CONNECT_COURSES_SUCCESS',

    FETCH_A_CLUB_CONNECT_COURSES_PROCESS: 'FETCH_A_CLUB_CONNECT_COURSES_PROCESS',
    FETCH_A_CLUB_CONNECT_COURSES_SUCCESS: 'FETCH_A_CLUB_CONNECT_COURSES_SUCCESS',

    FETCH_CLUB_CONNECT_ARTICLE_PROCESS: 'FETCH_CLUB_CONNECT_ARTICLE_PROCESS',
    FETCH_CLUB_CONNECT_ARTICLE_SUCCESS: 'FETCH_CLUB_CONNECT_ARTICLE_SUCCESS',

    FETCH_CLUB_CONNECT_CAC_PROCESS: 'FETCH_CLUB_CONNECT_CAC_PROCESS',
    FETCH_CLUB_CONNECT_CAC_SUCCESS: 'FETCH_CLUB_CONNECT_CAC_SUCCESS',

    FETCH_BIXMIX_PROCESS: 'FETCH_BIXMIX_PROCESS',
    FETCH_BIXMIX_SUCCESS: 'FETCH_BIXMIX_SUCCESS',

    FETCH_ALL_BIXMIX_PROCESS: 'FETCH_ALL_BIXMIX_PROCESS',
    FETCH_ALL_BIXMIX_SUCCESS: 'FETCH_ALL_BIXMIX_SUCCESS',

    FETCH_MERCHANT_PROCESS: 'FETCH_MERCHANT_PROCESS',
    FETCH_MERCHANT_SUCCESS: 'FETCH_MERCHANT_SUCCESS',

    FETCH_ALL_MERCHANT_PROCESS: 'FETCH_ALL_MERCHANT_PROCESS',
    FETCH_ALL_MERCHANT_SUCCESS: 'FETCH_ALL_MERCHANT_SUCCESS',


    FETCH_MERCHANT_PRODUCT_PROCESS: 'FETCH_MERCHANT_PRODUCT_PROCESS',
    FETCH_MERCHANT_PRODUCT_SUCCESS: 'FETCH_MERCHANT_PRODUCT_SUCCESS',

    FETCH_ALL_MERCHANT_PRODUCT_PROCESS: 'FETCH_ALL_MERCHANT_PRODUCT_PROCESS',
    FETCH_ALL_MERCHANT_PRODUCT_SUCCESS: 'FETCH_ALL_MERCHANT_PRODUCT_SUCCESS'
}