import { toast } from "react-toastify";
const moment = require("moment");

export const redirect_uri = `${window.location.origin}/reset-password`;

export const formatTime = (date) => {
  if (date) {
    const dateTime = moment(date).format("Do MMM YYYY");
    return dateTime;
  } else {
    return "";
  }
};

export const reload_page = () => {
  setTimeout(() => {
    window.location.reload();
  }, 2000);
};

export const notify = (x) => {
  toast(x);
};

export const FormatAmount = (amount) => {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Used to get the time difference between current time and a given time.
// A unix timestamp or a format of 'yyyy-MM-dd'T'HH:mm:ss' must be passed as the given time.
export const timeAgo = (date) => {
  const time = new Date(date).getTime()
  const now = new Date().getTime()
  const diff = (now - time) / 1000
  const hour = 3600
  const day = 86400
  const week = day * 7
  const month = day * 30
  const year = day * 365
  if (diff < 30) {
      return 'just now'
  } else if (diff < hour) {
      return Math.floor(diff / 60) + ' minutes ago'
  } else if (diff < day) {
      return Math.floor(diff / hour) + ' hours ago'
  } else if (diff < week) {
      return Math.floor(diff / day) + ' day(s) ago'
  } else if (diff < month) {
      return Math.floor(diff / week) + ' week(s) ago'
  } else if (diff < year) {
      return Math.floor(diff / month) + ' month(s) ago'
  } else {
      return Math.floor(diff / year) + ' year(s) ago'
  }
}

export const slugify = (text) => {
  const newText = text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/--+/g, '-').replace(/^-+/, '').replace(/-+$/, '');
  return newText;
};

export const spacesToUnderscores = (text) => {
  const newText = text.toLowerCase().toString().replace(/\s+/g, '_');
  return newText;
};

// Add commas to numbers < 1000, e.g., 1,000,000
export const commafy = (num) => {
  const newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return newNum;
}

// Copy text to clipboard
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
  .then()
  .catch((err) => {
      console.log(err);
  });
}

// Get a parameter from the URL
export const getSearchParam = (parameterName) => {
  var result = null, tmp = [];
  var items = window.location.search.substr(1).split("&");
  for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
}

// Regex to check if a string is a valid email address
export const isValidEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Regex to check if a string is a valid URL
export const isValidURL = (url) => {
  var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;
  return re.test(String(url).toLowerCase());
}

// Convert ISO timestamp to a human readable date and time
export const formatISODateTime = (date) => {
  var d = new Date(date);
  var month = d.getMonth() + 1;
  var day = d.getDate();
  var year = d.getFullYear();
  var hour = d.getHours();
  var minute = d.getMinutes();
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'
  minute = minute < 10 ? '0'+minute : minute;
  var strTime = month + '/' + day + '/' + year + ' ' + hour + ':' + minute + ' ' + ampm;
  return strTime;
}

// Convert 24-hour time to 12-hour time
export const format24HourTime = (time) => {
  var hour = parseInt(time.substr(0,2));
  var minute = time.substr(3,2);
  var ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'
  minute = minute < 10 ? minute : '0'+minute;
  var strTime = hour + ':' + minute + ' ' + ampm;
  return strTime;
}

// Round decimals to a given number of decimal places
export const roundDecimals = (num, decimals) => {
  var newNum = Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return newNum;
}

// Convert youtube url to an embed link
export const youtubeUrlToEmbed = (url) => {
  // First check if the url is a valid youtube url
  if (isValidURL(url)) {
      // Get the video id from the url
      var videoId = url.split('v=')[1];
      if (videoId) {
          // Check if the video id has an ampersand in it
          if (videoId.indexOf('&') !== -1) {
              // If it does, remove it
              videoId = videoId.split('&')[0];
          }
          // Check if the video id has a '?' in it
          if (videoId.indexOf('?') !== -1) {
              // If it does, remove it
              videoId = videoId.split('?')[0];
          }
          // Check if the video id has a '#' in it
          if (videoId.indexOf('#') !== -1) {
              // If it does, remove it
              videoId = videoId.split('#')[0];
          }

          // Return the embed link
          return 'https://www.youtube.com/embed/' + videoId;
      }
  }
  // If the url is not a valid youtube url, return the url
  return url;
}

// Convert HTML and entities to plain text
export const htmlToText = (html) => {
  var text = html.replace(/<(?:.|\s)*?>/g, '');

  // Remove all HTML entities
  text = text.replace(/&nbsp;/g, ' ');
  text = text.replace(/&amp;/g, '&');
  text = text.replace(/&quot;/g, '"');
  text = text.replace(/&lt;/g, '<');
  text = text.replace(/&gt;/g, '>');
  text = text.replace(/&#39;/g, "'");
  text = text.replace(/&#34;/g, '"');
  text = text.replace(/&#8211;/g, '-');
  text = text.replace(/&#8220;/g, '"');
  text = text.replace(/&#8221;/g, '"');
  text = text.replace(/&#8217;/g, "'");
  text = text.replace(/&#8230;/g, '...');
  text = text.replace(/&#8212;/g, '-');

  // Remove all new lines
  text = text.replace(/\n/g, ' ');

  // Remove all multiple spaces
  text = text.replace(/\s+/g, ' ');

  // Remove all leading and trailing spaces
  text = text.trim();

  return text;
}

// Check is a string is all numbers
export const isNumeric = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

// Encode to base64
export const base64Encode = (data) => {
  data = JSON.stringify(data);
  return btoa(data);
}

// Decode from base64
export const base64Decode = (data) => {
  data = atob(data);
  return JSON.parse(data);
}

// Set cookie
/**
* 
* @param {string} name - The name of the cookie
* @param {string} value - The value of the cookie
* @param {int} days - The number of days until the cookie expires
*/
export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=." + process.env.REACT_APP_DOMAIN_NAME;
}

// Get cookie
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Delete cookie
export const deleteCookie = (name) => {
  setCookie(name, "", -1);
  return;
}

// Check if a string is a JSON string
export const isJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

// Convert object to formdata
export const objectToFormData = (obj) => {
  var fd = new FormData();
  for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
          fd.append(key, obj[key]);
      }
  }
  return fd;
}

export const capitalizeFirstLetter = (string) => {
  if(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const populateProperties = (listing) => {
  return Object.fromEntries(listing.properties.map(x => {
      return [x.propertible_id, x.propertible.has_extras ? (
          Object.fromEntries([
              [x.valuable.listing_category_property_id, x.valuable.id]
          ])
      ) : (x.propertible.optional ? x.user_input : x.valuable_id)]
  }));
}


export const dataTypeToInputType = (dataType) => {
  if (dataType === "longtext") {
    return "textarea";
  } else if (dataType === "string" || dataType === "text") {
    return "text";
  } else if (dataType === "url") {
    return "url";
  } else if (dataType === "integer" || dataType === "percentage") {
    return "number";
  } else if (dataType === "boolean") {
    return "checkbox";
  } else if (dataType === "date" || dataType === "date_after_today" || dataType === "date_before_today") {
    return "date";
  } else if (dataType === "datetime") {
    return "datetime-local";
  } else if (dataType === "time") {
    return "time";
  } else if (dataType === "file" || dataType === "image") {
    return "file";
  }
};

// Generate random string
export const generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}