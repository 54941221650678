import { RoleTypes } from "./roles.types"

const INITIAL_STATE = {
    roles: [],
    permissions: [],
    loading: false,
    role: {}
}

const modelPermissions = (permissions) => {
    return permissions.map(permission => {
        return {
            label: permission.name + ' | ' + permission.group_name,
            value: permission.id
        }
    })
}

const rolesReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case RoleTypes.FETCH_ALL_ROLES_PROCESS:
            return { ...state, roles: [], loading: true }
        case RoleTypes.FETCH_ALL_ROLES_SUCCESS:
            return { ...state, roles: payload, loading: false }

        case RoleTypes.FETCH_A_ROLE_PROCESS:
            return { ...state, role: {}, loading: true }
        case RoleTypes.FETCH_A_ROLE_SUCCESS:
            return { ...state, role: payload, loading: false }

        case RoleTypes.FETCH_ALL_PERMISSION_PROCESS:
            return { ...state, permissions: [], loading: true }
        case RoleTypes.FETCH_ALL_PERMISSION_SUCCESS:
            return { ...state, permissions: modelPermissions(payload.data), loading: false }
        default:
            return state
    }
}

export default rolesReducer;