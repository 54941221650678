import { alertTypes } from "./alert.types";

export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    return { 
        type: alertTypes.ALERT_SUCCESS, 
        payload: message 
    };
}

function error(message) {
    return { 
        type: alertTypes.ALERT_ERROR, 
        payload: message 
    };
}

function clear() {
    return { 
        type: alertTypes.ALERT_CLEAR
    };
}