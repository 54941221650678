import React, { useEffect } from "react";
import { Switch, Route, Redirect , useHistory} from "react-router-dom";
import { createStructuredSelector } from 'reselect'
// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";
import { connect } from "react-redux";
import { selectAError, selectASuccess } from "../redux/alert/alert.selectors.js";
import Error from "../components/Alert/Error.js";
import Success from "../components/Alert/Success.js";
import { notify } from "../helpers.js";
import { toast } from "react-toastify";


const Admin = ({ children, error, success }) => {
  const history = useHistory
  useEffect(() => {
    console.log(error)
  }, [])
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64  bg-blueGray-100">
      
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
        {/* <div className="mb-3">
              <small className="cursor-pointer" onClick={() => history.goBack()}>  Go back</small>
              
              </div> */}
          {children}
        </div>
      </div>
      <div className="absolute bottom-0 right-0">
        {/* {error && <Error message={error} />} */}
        {error && toast.error(error)}
        {/* {success && <Success message={success} />} */}
        {success && toast.success(success)}
      </div>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  error: selectAError,
  success: selectASuccess
})
export default connect(mapStateToProps, null)(Admin)