import { combineReducers } from 'redux';
import dashboardStats from './dashboard.reducer';
import { alertReducer } from '../redux/alert/alert.reducers'
import listingsReducer from '../redux/listings/listings.reducer';
import locationReducer from '../redux/location/location.reducer'
import claimBusinessReducer from '../redux/claim-business/claim-business.reducer';
import businessReducer from '../redux/business/business.reducer';
import auditLogReducer from '../redux/audit-log/audit-log.reducer';
import usersReducer from '../redux/users/users.reducers';
import rolesReducer from '../redux/roles/roles.reducers';
import bizmixUsersReducer from "../redux/users/bizmix.users.reducers";
import advertReducer from '../redux/advert/advert.reducer';
import newsLetterReducer from "../redux/newsletter/newsletter.reducers";
import bannerReducer from "../redux/banner/banner.reducers";
import salesLeadsReducer from "../redux/sales-lead/sales-lead.reducer";
import clubConnectReducer from '../redux/club-connect/club-connect.reducer';
import statesReducer from '../redux/state/state.reducers'
const rootReducer = combineReducers({
  dashboardStats,
  alertReducer,
  listingsReducer,
  locationReducer,
  claimBusinessReducer,
  businessReducer,
  auditLogReducer,
  usersReducer,
  bizmixUsersReducer,
  rolesReducer,
  advertReducer,
  newsLetterReducer,
  bannerReducer,
  salesLeadsReducer,
  clubConnectReducer,
  statesReducer
});

export default rootReducer;
