import axios from "axios";
import values from "postcss-modules-values";
import CardStats from "../../components/Cards/CardStats";
import ConnectNigeria from "../../services/ConnectNigeria";
import {
  requestCreateListingCategory,
  requestCreateListingCategoryProperty,
  requestCreateListingSubCategory,
  requestCreateListingSubCategoryProperty,
  requestCreateListingSubCategoryPropertyValue,
  requestCreatePropertyExtra,
  requestCreatePropertyExtraValue,
  requestCreatePropertyValue,
  requestDeleteListingCategory,
  requestDeleteListingCategoryProperty,
  requestDeleteListingSubCategory,
  requestDeleteListingSubCategoryProperty,
  requestDeleteListingSubCategoryPropertyValue,
  requestDeletePropertyExtra,
  requestDeletePropertyExtraValue,
  requestDeletePropertyValue,
  requestFetchAllListings,
  requestFetchDataTypes,
  requestGetAListingCategoryProperty,
  requestGetAPropertyExtra,
  requestGetAPropertyExtraValue,
  requestGetAPropertyValue,
  requestGetListingCategory,
  requestGetListingSubCategory,
  requestGetListingSubCategoryProperty,
  requestGetListingSubCategoryPropertyValue,
  requestListingCategory,
  requestListingCategoryProperty,
  requestListingSubCategory,
  requestListingSubCategoryProperty,
  requestListingSubCategoryPropertyValue,
  requestPropertyExtras,
  requestPropertyExtraValues,
  requestPropertyValues,
  requestRestoreDeletedListingCategory,
  requestRestoreListingCategoryProperty,
  requestRestoreListingSubCategory,
  requestRestoreListingSubCategoryProperty,
  requestRestorePropertyValue,
  requestToggleActiveStateOfListingCategoryProperty,
  requestToggleMandatoryStateOfListingCategoryProperty,
  requestUpdateListingCategory,
  requestUpdateListingCategoryProperty,
  requestUpdateListingSubCategory,
  requestUpdateListingSubCategoryProperty,
  requestUpdateListingSubCategoryPropertyValue,
  requestUpdatePropertyExtra,
  requestUpdatePropertyExtraValue,
  requestUpdatePropertyValue,
} from "../../services/requests/listing.requests";
import { alertActions } from "../alert/alert.actions";
import { alertTypes } from "../alert/alert.types";
import { ListingTypes } from "./listings.types";
import { getToken } from "../../services/helper";
import Swal from "sweetalert2";
import { reload_page } from "../../helpers";
export const listingsActions = {
  getListing,
  addListing,
  getPaginateList,
};

function getPaginateList(endpoint) {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS,
    });

    try {
      const { data } = await ConnectNigeria.get(endpoint);
      if (data) {
        dispatch({
          type: ListingTypes.FETCH_LISTINGS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: ListingTypes.FETCH_LISTINGS_PROCESS,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}
function getListing(catId, next = false, prev = false, per_page = 15, last_added_sort = null, is_featured = 0, location) {
  return async (dispatch, getState) => {
    let page_no = getState()?.listingsReducer?.listings?.current_page;
    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }

    dispatch({
      type: ListingTypes.FETCH_LISTINGS_PROCESS,
    });

    try {
      const { data: { data } } = await ConnectNigeria.get(
        `/admin/listings?per_page=${per_page}&filter[listing_category_id]=${catId}&include=viewsCount,creator,listingCategory,listingSubCategory,owner,properties,locations&page=${page_no}&filter[trashed]=with${location ? `&filter[location]=${location}` : ''}${is_featured ? `&filter[is_featured]=${is_featured}` : ''}${last_added_sort ? `&sort=${last_added_sort}` : ''}`
      );
  
      dispatch({
        type: ListingTypes.FETCH_LISTINGS_SUCCESS,
        payload: data.listings,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

function addListing(formData) {
  return async (dispatch) => {
    // const {data} = await  ConnectNigeria.post('/admin/listings', formData)
    const data = await axios
      .post(`${process.env.REACT_APP_API_HOST}/admin/listings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: getToken() && `Bearer ${getToken()}`,
        },
      })
      .then((res) => {
        if (res.status === 200 || 201 || 202) {
          dispatch(alertActions.success("Listing added successfully, please continue"));
          dispatch({
            type: ListingTypes.ADD_LISTING_SUCCESS,
            payload: res.data,
          });

        }
        // setTimeout(() => {window.history.back()}, 500)
      })

      .catch((err) => {
        let errors = err?.response?.data?.errors
        console.log(err?.response?.data?.errors);
        if (err?.response?.data?.errors) {
          Object.keys(errors).map(key => (
            dispatch(alertActions.error(errors[key]))
          ))
            dispatch(alertActions.error(Object.keys(errors).map(key => errors[key]).join(", ")))

        }
      
        dispatch(alertActions.error("Please check all fields"));
        console.log(err?.response);
      });

    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 500);


  };
}

export const updateLocation = (listId, locationID, locationData) => {
  return async (dispatch) => {
    const data = await ConnectNigeria.post(`/admin/listings/${listId}/locations/${locationID}`,locationData)
    .then((res) => {
      console.log(res);
      if (res.status === 200 || 201 || 202) {
        dispatch(alertActions.success("Listing Updated Successfully"));
        dispatch({
          type: ListingTypes.ADD_LISTING_SUCCESS,
          payload: res.data,
        });
        
        window.location.reload();
      }
    })
    .catch((err) => {
      let errors = err?.response?.data?.errors
      console.log(err?.response?.data?.errors);
      if (err?.response?.data?.errors) {
        Object.keys(errors).map(key => (
          dispatch(alertActions.error(errors[key]))
        ))
          dispatch(alertActions.error(Object.keys(errors).map(key => errors[key]).join(", ")))

      }
    
      dispatch(alertActions.error("Please check locations"));
      console.log(err?.response);
    });

    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 500);
  }
}

export const updateListing = (listId, formData) => {
  return async (dispatch) => {
    const data = await axios
      .post(
        `${process.env.REACT_APP_API_HOST}/admin/listings/${listId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getToken() && `Bearer ${getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200 || 201 || 202) {
          dispatch(alertActions.success("Listing Updated Successfully"));
          dispatch({
            type: ListingTypes.ADD_LISTING_SUCCESS,
            payload: res.data,
          });

          window.location.reload();
        }

        // return res.data

        // setTimeout(() => {window.history.back()}, 500)
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors
        console.log(err?.response?.data?.errors);
        if (err?.response?.data?.errors) {
          Object.keys(errors).map(key => (
            dispatch(alertActions.error(errors[key]))
          ))
            dispatch(alertActions.error(Object.keys(errors).map(key => errors[key]).join(", ")))

        }
      
        dispatch(alertActions.error("Please check all fields"));
        console.log(err?.response);
      });

    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 500);

    // dispatch({
    //   type: ListingTypes.ADD_LISTING_SUCCESS,
    //   // payload: data
    // })

    //  dispatch(alertActions.clear());
  };
};

export const searchListingList = (search, catId, next = false, prev = false, per_page = 15, last_added_sort = null, is_featured = 0, location) => {
  return async (dispatch) => {
    const {
      data: {
        data: { listings },
      },
      // `/admin/listings?sort=-created_at&per_page=${per_page}&filter[listing_category_id]=${catId}&include=viewsCount,listingCategory,listingSubCategory,owner,properties,locations&page=${page_no} `

    } = await ConnectNigeria.get(
      `/admin/listings?per_page=${per_page}&include=viewsCount,creator,listingCategory,listingSubCategory,owner,properties,locations&filter[name]=${search}&filter[listing_category_id]=${catId}${location ? `&filter[location]=${location}` : ''}${is_featured ? `&filter[is_featured]=${is_featured}` : ''}${last_added_sort ? `&sort=${last_added_sort}` : ''}&filter[trashed]=with`
    );
    dispatch({
      type: ListingTypes.FETCH_LISTINGS_SUCCESS,
      payload: listings,
    });
  };
}

export const getSingleListing = (listId) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROCESS,
    });

    try {
      const {
        data: { data },
      } = await ConnectNigeria.get(
        `/admin/listings/${listId}}?include=properties,owner,listingCategory,listingSubCategory,tags,locations`
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING,
        payload: data,
      });
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const deleteListing = (type, listId) => {
  return async (dispatch) => {
    try {
      await ConnectNigeria.delete(`/admin/${type}/${listId}`);
      dispatch(getListing());
    } catch (error) {
      console.log(error.response);
    }
  }; 
};

export const resListing = (listId) => {
  return async (dispatch) => {
    try {
      const data =  await ConnectNigeria.post(`admin/listings/${listId}/restore`)
     console.log(data);
     if (data.status === 200) {
       reload_page()
      console.log("restored");
     }
     dispatch(getListing());
      console.log("restored");
    } catch (error) {
      console.log(error.response);
    }
  }; 
};

// export const restoreListing = (listId) => {
//   return async (dispatch) => {
//     try {
//     const data =  await ConnectNigeria.post(`admin/listings/${listId}/restore`)
//      console.log(data);
//      if (data.status === 200) {
//       //  reload_page()
//       console.log("restored");

//      }
//       dispatch(getListing());
//       console.log("restored");
//     }
//     catch (error) { 
//       console.log(error.response);
//     }
//   }
// }



export const verifyListing = (listId) => {
  return async (dispatch) => {
    try {
      await ConnectNigeria.patch(`/admin/listings/${listId}/verify/toggle`);
      dispatch(getListing());
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const activateListing = (listId) => {
  return async (dispatch) => {
    try {
      await ConnectNigeria.patch(`/admin/listings/${listId}/activate/toggle`);
      dispatch(getListing());
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const suspendListing = (type, listId) => {
  return async (dispatch) => {
    try {
      await ConnectNigeria.patch(`/admin/${type}/${listId}/block/toggle`);
      dispatch(getListing());
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const getListingCategory = (
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no = getState()?.listingsReducer?.listing_categories?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }

    dispatch({
      type: ListingTypes.FETCH_LISTING_CATEGORY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_categories },
        },
      } = await requestListingCategory(page_no, per_page);

      dispatch({
        type: ListingTypes.FETCH_LISTING_CATEGORY_SUCCESS,
        payload: listing_categories,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const createListingCategory = (values, history) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreateListingCategory(values);
      dispatch(alertActions.success(message));

      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push("/admin/listing-category");
      }, 3000);
    } catch (error) {
      const errors = error.response.data.errors;

      if (errors.name) {
        dispatch(alertActions.error(errors.name[0]));
      }

      if (errors.description) {
        dispatch(alertActions.error(errors.description[0]));
      }

      if (errors.featured_image) {
        dispatch(alertActions.error(errors.featured_image[0]));
      }

      if (errors.icon) {
        dispatch(alertActions.error(errors.icon[0]));
      }
    }
  };
};

export const deleteListingCategory = (listingCategoryId) => {
  return async (dispatch) => {
    try {
      await requestDeleteListingCategory(listingCategoryId);

      dispatch(getListingCategory());
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const getAListingCategory = (listingCategoryId) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_category },
        },
      } = await requestGetListingCategory(listingCategoryId);

      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_SUCCESS,
        payload: listing_category,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const updateAListingCategory = (listingCategoryId, payload, history) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.UPDATE_LISTING_CATEGORY_PROCESS,
    });

    try {
      const {
        data: { message },
      } = await requestUpdateListingCategory(listingCategoryId, payload);

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      dispatch({
        type: ListingTypes.UPDATE_LISTING_CATEGORY_SUCCESS,
      });

      setTimeout(() => {
        history.push("/admin/listing-category");
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const restoreDeletedListingCategory = (listingCategoryId) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.RESTORE_LISTING_CATEGORY_PROCESS,
    });

    try {
      const {
        data: { message },
      } = await requestRestoreDeletedListingCategory(listingCategoryId);

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      dispatch(getListingCategory());
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const fetchListingCategoryProperty = (
  listingCategoryId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    dispatch(alertActions.clear());
    let page_no =
      getState()?.listingsReducer?.listing_category_properties?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }

    dispatch({
      type: ListingTypes.FETCH_LISTING_CATEGORY_PROPERTY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_category_properties },
        },
      } = await requestListingCategoryProperty(
        listingCategoryId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_LISTING_CATEGORY_PROPERTY_SUCCESS,
        payload: listing_category_properties,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const createListingCategoryProperty = (
  listingCategoryId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreateListingCategoryProperty(listingCategoryId, values);

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(`/admin/listing-category/${listingCategoryId}/properties`);
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const getAListingCategoryProperty = (listingCategoryId, propertyId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_PROCESS,
      });
      const {
        data: {
          data: { listing_category_property },
        },
      } = await requestGetAListingCategoryProperty(
        listingCategoryId,
        propertyId
      );
      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_CATEGORY_PROPERTY_SUCCESS,
        payload: listing_category_property,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const updateListingCategoryProperty = (
  listingCategoryId,
  propertyId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdateListingCategoryProperty(
        listingCategoryId,
        propertyId,
        values
      );
      dispatch(alertActions.success(message));

      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(`/admin/listing-category/${listingCategoryId}/properties`);
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const destroyListingCategoryProperty = (
  listingCategoryId,
  propertyId
) => {
  return async (dispatch) => {
    try {
      await requestDeleteListingCategoryProperty(listingCategoryId, propertyId);
      dispatch(fetchListingCategoryProperty(listingCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const restoreListingCategoryProperty = (
  listingCategoryId,
  propertyId
) => {
  return async (dispatch) => {
    try {
      await requestRestoreListingCategoryProperty(
        listingCategoryId,
        propertyId
      );
      dispatch(fetchListingCategoryProperty(listingCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const getPropertyValues = (
  listingCategoryId,
  propertyId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no = getState()?.listingsReducer?.property_values?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }
    try {
      dispatch({
        type: ListingTypes.FETCH_PROPERTY_VALUES_PROCESS,
      });
      const {
        data: {
          data: { listing_category_property_values },
        },
      } = await requestPropertyValues(
        listingCategoryId,
        propertyId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_PROPERTY_VALUES_SUCCESS,
        payload: listing_category_property_values,
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
};

export const createPropertyValue = (
  listingCategoryId,
  propertyId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreatePropertyValue(
        listingCategoryId,
        propertyId,
        values
      );
      dispatch(getPropertyValues(listingCategoryId, propertyId));

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/values`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const updatePropertyValue = (
  listingCategoryId,
  propertyId,
  valueId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdatePropertyValue(
        listingCategoryId,
        propertyId,
        valueId,
        values
      );
      dispatch(getAPropertyValue(listingCategoryId, propertyId, valueId));
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/values`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const getAPropertyValue = (listingCategoryId, propertyId, valueId) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_PROPERTY_VALUES_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_category_property_value },
        },
      } = await requestGetAPropertyValue(
        listingCategoryId,
        propertyId,
        valueId
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_PROPERTY_VALUES_SUCCESS,
        payload: listing_category_property_value,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const restorePropertyValue = (
  listingCategoryId,
  propertyId,
  valueId
) => {
  return async (dispatch) => {
    try {
      await requestRestorePropertyValue(listingCategoryId, propertyId, valueId);

      dispatch(getPropertyValues(listingCategoryId, propertyId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deletePropertyValue = (listingCategoryId, propertyId, valueId) => {
  return async (dispatch) => {
    try {
      await requestDeletePropertyValue(listingCategoryId, propertyId, valueId);

      dispatch(getPropertyValues(listingCategoryId, propertyId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchPropertyExtras = (
  listingCategoryId,
  propertyId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no = getState()?.listingsReducer?.property_extras?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }
    try {
      dispatch({
        type: ListingTypes.FETCH_PROPERTY_EXTRAS_PROCESS,
      });
      const {
        data: {
          data: { listing_category_property_extras },
        },
      } = await requestPropertyExtras(
        listingCategoryId,
        propertyId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_PROPERTY_EXTRAS_SUCCESS,
        payload: listing_category_property_extras,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
    }
  };
};

export const getAPropertyExtra = (listingCategoryId, propertyId, extraId) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_category_property_extra },
        },
      } = await requestGetAPropertyExtra(
        listingCategoryId,
        propertyId,
        extraId
      );
      dispatch({
        type: ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_SUCCESS,
        payload: listing_category_property_extra,
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };
};

export const updatePropertyExtra = (
  listingCategoryId,
  propertyId,
  extraId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdatePropertyExtra(
        listingCategoryId,
        propertyId,
        extraId,
        values
      );
      dispatch(getAPropertyExtra(listingCategoryId, propertyId, extraId));

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/extras`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deletePropertyExtra = (listingCategoryId, propertyId, extraId) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestDeletePropertyExtra(
        listingCategoryId,
        propertyId,
        extraId
      );

      dispatch(fetchPropertyExtras(listingCategoryId, propertyId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const createPropertyExtra = (
  listingCategoryId,
  propertyId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreatePropertyExtra(
        listingCategoryId,
        propertyId,
        values
      );
      dispatch(fetchPropertyExtras(listingCategoryId, propertyId));
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/extras`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchExtraValues = (
  listingCategoryId,
  propertyId,
  extraId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no =
      getState()?.listingsReducer?.property_extra_values?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }

    dispatch({
      type: ListingTypes.FETCH_PROPERTY_EXTRAS_VALUES_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_category_property_extra_values },
        },
      } = await requestPropertyExtraValues(
        listingCategoryId,
        propertyId,
        extraId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_PROPERTY_EXTRAS_VALUES_SUCCESS,
        payload: listing_category_property_extra_values,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchAExtraValues = (
  listingCategoryId,
  propertyId,
  extraId,
  extraValueId
) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_category_property_extra_value },
        },
      } = await requestGetAPropertyExtraValue(
        listingCategoryId,
        propertyId,
        extraId,
        extraValueId
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_PROPERTY_EXTRAS_VALUES_SUCCESS,
        payload: listing_category_property_extra_value,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const createPropertyExtraValue = (
  listingCategoryId,
  propertyId,
  extraId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreatePropertyExtraValue(
        listingCategoryId,
        propertyId,
        extraId,
        values
      );

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/extras/${extraId}/values`
        );
      }, 3000);
    } catch (error) {
      console.log(error.response.data);
      dispatch(alertActions.error(error.response.data.message));
    }
  };
};

export const updatePropertyExtraValue = (
  listingCategoryId,
  propertyId,
  extraId,
  extraValueId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdatePropertyExtraValue(
        listingCategoryId,
        propertyId,
        extraId,
        extraValueId,
        values
      );
      dispatch(
        fetchAExtraValues(listingCategoryId, propertyId, extraId, extraValueId)
      );

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/property/${propertyId}/extras/${extraId}/values`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deletePropertyExtraValues = (
  listingCategoryId,
  propertyId,
  extraId,
  extraValueId
) => {
  return async (dispatch) => {
    try {
      await requestDeletePropertyExtraValue(
        listingCategoryId,
        propertyId,
        extraId,
        extraValueId
      );

      dispatch(fetchExtraValues(listingCategoryId, propertyId, extraId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const getListingSubCategory = (
  listingCategoryId,
  next = false,
  prev = false,
  per_page = 100
) => {
  return async (dispatch, getState) => {
    let page_no =
      getState()?.listingsReducer?.listing_sub_categories?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }

    dispatch({
      type: ListingTypes.FETCH_LISTING_SUBCATEGORY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_sub_categories },
        },
      } = await requestListingSubCategory(listingCategoryId, page_no, per_page);

      dispatch({
        type: ListingTypes.FETCH_LISTING_SUBCATEGORY_SUCCESS,
        payload: listing_sub_categories,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const createListingSubcategory = (
  listingCategoryId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreateListingSubCategory(listingCategoryId, values);

      dispatch(alertActions.success(message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);

      setTimeout(() => { history.push(`/admin/listing-category/${listingCategoryId}/sub-category`) }, 3000)
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const updateListingCategory = (
  listingCategoryId,
  listingSubCategoryId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdateListingSubCategory(
        listingCategoryId,
        listingSubCategoryId,
        values
      );

      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/sub-category`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const getAListingSubCategory = (
  listingCategoryId,
  listingSubCategoryId
) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_sub_category },
        },
      } = await requestGetListingSubCategory(
        listingCategoryId,
        listingSubCategoryId
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_SUCCESS,
        payload: listing_sub_category,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deleteListingSubCategory = (
  listingCategoryId,
  listingSubCategoryId
) => {
  return async (dispatch) => {
    try {
      await requestDeleteListingSubCategory(
        listingCategoryId,
        listingSubCategoryId
      );

      dispatch(getListingSubCategory(listingCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const restoreListingSubCategory = (
  listingCategoryId,
  listingSubCategoryId
) => {
  return async (dispatch) => {
    try {
      await requestRestoreListingSubCategory(
        listingCategoryId,
        listingSubCategoryId
      );

      dispatch(getListingSubCategory(listingCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const getSubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no =
      getState()?.listingsReducer?.sub_categories_properties?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }
    dispatch({
      type: ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_sub_category_properties },
        },
      } = await requestListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_SUCCESS,
        payload: listing_sub_category_properties,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  };
};

export const getASubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId
) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_PROCESS,
    });

    try {
      const {
        data: {
          data: { listing_sub_category_property },
        },
      } = await requestGetListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_SUCCESS,
        payload: listing_sub_category_property,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const createListingSubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreateListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        values
      );

      dispatch(alertActions.success(message));

      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/sub-category/${listingSubCategoryId}/properties`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const updateListingSubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdateListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        values
      );
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/sub-category/${listingSubCategoryId}/properties`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deleteListingSubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId
) => {
  return async (dispatch) => {
    try {
      await requestDeleteListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId
      );

      dispatch(getSubCategoryProperty(listingCategoryId, listingSubCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const restoreListingSubCategoryProperty = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId
) => {
  return async (dispatch) => {
    try {
      await requestRestoreListingSubCategoryProperty(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId
      );
      dispatch(getSubCategoryProperty(listingCategoryId, listingSubCategoryId));
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchListingSubCategoryPropertyValue = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  next = false,
  prev = false,
  per_page = 10
) => {
  return async (dispatch, getState) => {
    let page_no =
      getState()?.listingsReducer?.sub_category_property_values?.current_page;

    if (next) {
      page_no += 1;
    }

    if (prev) {
      page_no -= 1;
    }
    dispatch({
      type: ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_sub_category_property_values },
        },
      } = await requestListingSubCategoryPropertyValue(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        page_no,
        per_page
      );

      dispatch({
        type: ListingTypes.FETCH_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS,
        payload: listing_sub_category_property_values,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchAListingSubCategoryPropertyValue = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  listingSubCategoryPropertyValueId
) => {
  return async (dispatch) => {
    dispatch({
      type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_PROCESS,
    });
    try {
      const {
        data: {
          data: { listing_sub_category_property_value },
        },
      } = await requestGetListingSubCategoryPropertyValue(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        listingSubCategoryPropertyValueId
      );

      dispatch({
        type: ListingTypes.FETCH_SINGLE_LISTING_SUBCATEGORY_PROPERTY_VALUE_SUCCESS,
        payload: listing_sub_category_property_value,
      });
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const deleteListingSubCategoryPropertyValue = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  listingSubCategoryPropertyValueId
) => {
  return async (dispatch) => {
    try {
      await requestDeleteListingSubCategoryPropertyValue(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        listingSubCategoryPropertyValueId
      );

      dispatch(
        fetchListingSubCategoryPropertyValue(
          listingCategoryId,
          listingSubCategoryId,
          listingSubCategoryPropertyId
        )
      );
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const createListingSubCategoryPropertyValue = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestCreateListingSubCategoryPropertyValue(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        values
      );

      dispatch(alertActions.success(message));

      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/sub-category/${listingSubCategoryId}/property/${listingSubCategoryPropertyId}/values`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const updateListingSubCategoryPropertyValue = (
  listingCategoryId,
  listingSubCategoryId,
  listingSubCategoryPropertyId,
  listingSubCategoryPropertyValueId,
  values,
  history
) => {
  return async (dispatch) => {
    try {
      const {
        data: { message },
      } = await requestUpdateListingSubCategoryPropertyValue(
        listingCategoryId,
        listingSubCategoryId,
        listingSubCategoryPropertyId,
        listingSubCategoryPropertyValueId,
        values
      );
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      setTimeout(() => {
        history.push(
          `/admin/listing-category/${listingCategoryId}/sub-category/${listingSubCategoryId}/property/${listingSubCategoryPropertyId}/values`
        );
      }, 3000);
    } catch (error) {
      dispatch(alertActions.error(error.response.data.message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
    }
  };
};

export const fetchAllListings = (name, per_page = 100) => {
  return async dispatch => {
    dispatch({
      type: ListingTypes.FETCH_ALL_LISTINGS_PROCESS
    })

    try {
      const { data: { data: { listings } } } = await requestFetchAllListings(name, per_page);
      dispatch({
        type: ListingTypes.FETCH_ALL_LISTINGS_SUCCESS,
        payload: listings
      })
    } catch (error) {
      dispatch(alertActions.error(error?.response?.data?.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  }
}

export const togglePropertyAciveStatus = (listingCategoryId, listingCategoryPropertyId) => {
  return async dispatch => {
    try {
      const { data: { message } } = await requestToggleActiveStateOfListingCategoryProperty(listingCategoryId, listingCategoryPropertyId);
      console.log('aye aye')
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      dispatch(fetchListingCategoryProperty(listingCategoryId));

    } catch (error) {
      dispatch(alertActions.error(error?.response?.data?.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  }
}

export const togglePropertyMAndatoryStatus = (listingCategoryId, listingCategoryPropertyId) => {
  return async dispatch => {
    try {
      const { data: { message } } = await requestToggleMandatoryStateOfListingCategoryProperty(listingCategoryId, listingCategoryPropertyId);
      console.log('aye aye')
      dispatch(alertActions.success(message));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 1000);
      dispatch(fetchListingCategoryProperty(listingCategoryId));

    } catch (error) {
      dispatch(alertActions.error(error?.response?.data?.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  }
}

export const fetchDataTypes = () => {
  return async  dispatch => {
    dispatch({
      type: ListingTypes.FETCH_ALL_DATA_TYPES_PROCESS
    })
    try{
      const {data: {data: {datatypes}}} = await requestFetchDataTypes();

      dispatch({
        type: ListingTypes.FETCH_ALL_DATA_TYPES_SUCCESS,
        payload: datatypes
      })
    }catch(error){
      dispatch(alertActions.error(error?.response?.data?.message));
      setTimeout(() => { dispatch(alertActions.clear()) }, 1000);
    }
  }
}