import ConnectNigeria from "../ConnectNigeria"

export const requestListingCategory = async (page = 1, per_page) => {
    return await ConnectNigeria.get(`/admin/listing-categories?page=${page}&per_page=${per_page}&filter[trashed]=with&include=listingsCount`);
}

export const requestCreateListingCategory = async values => {
    return await ConnectNigeria.post('/admin/listing-categories', values);
}

export const requestDeleteListingCategory = async listingCategoryId => {
    return await ConnectNigeria.delete(`/admin/listing-categories/${listingCategoryId}`)
}

export const requestGetListingCategory = async listingCategoryId => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}`)
}

export const requestUpdateListingCategory = async (listingCategoryId, payload) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}`, payload)
}

export const requestRestoreDeletedListingCategory = async listingCategoryId => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/restore`)
}

export const requestListingCategoryProperty = async (listingCategoryId, page = 1, per_page) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties?filter[trashed]=with&page=${page}&per_page=${per_page}`)
}

export const requestCreateListingCategoryProperty = async (listingCategoryId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties`, values)
}

export const requestGetAListingCategoryProperty = async (listingCategoryId, propertyId) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}`)
}

export const requestUpdateListingCategoryProperty = async (listingCategoryId, propertyId, values) => {
    return await ConnectNigeria.put(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}`, values)
}

export const requestDeleteListingCategoryProperty = async (listingCategoryId, propertyId) => {
    return await ConnectNigeria.delete(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}`);
}

export const requestRestoreListingCategoryProperty = async (listingCategoryId, propertyId) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/restore`);
}

export const requestPropertyValues = async (listingCategoryId, propertyId, page = 1, per_page) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values?page=${page}&per_page=${per_page}`);
}

export const requestCreatePropertyValue = async (listingCategoryId, propertyId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values`, values)
}

export const requestUpdatePropertyValue = async (listingCategoryId, propertyId, valueId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values/${valueId}`, values)
}

export const requestGetAPropertyValue = async (listingCategoryId, propertyId, valueId) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values/${valueId}`)
}

export const requestDeletePropertyValue = async (listingCategoryId, propertyId, valueId) => {
    return await ConnectNigeria.delete(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values/${valueId}`)
}

export const requestRestorePropertyValue = async (listingCategoryId, propertyId, valueId) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/values/${valueId}/restore`)
}

export const requestPropertyExtras = async (listingCategoryId, propertyId, page = 1, per_page) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras?page=${page}&per_page=${per_page}`);
}

export const requestCreatePropertyExtra = async (listingCategoryId, propertyId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras`, values)
}

export const requestUpdatePropertyExtra = async (listingCategoryId, propertyId, extraId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}`, values)
}

export const requestGetAPropertyExtra = async (listingCategoryId, propertyId, extraId) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}`)
}

export const requestDeletePropertyExtra = async (listingCategoryId, propertyId, extraId) => {
    return await ConnectNigeria.delete(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}`)
}

export const requestPropertyExtraValues = async (listingCategoryId, propertyId, extraId, page = 1, per_page) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}/values?page=${page}&per_page=${per_page}`);
}

export const requestCreatePropertyExtraValue = async (listingCategoryId, propertyId, extraId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}/values`, values)
}

export const requestUpdatePropertyExtraValue = async (listingCategoryId, propertyId, extraId, extraValueId, values) => {
    return await ConnectNigeria.post(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}/values/${extraValueId}`, values)
}

export const requestGetAPropertyExtraValue = async (listingCategoryId, propertyId, extraId, extraValueId) => {
    return await ConnectNigeria.get(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}/values/${extraValueId}`)
}

export const requestDeletePropertyExtraValue = async (listingCategoryId, propertyId, extraId, extraValueId) => {
    return await ConnectNigeria.delete(`/admin/listing-categories/${listingCategoryId}/properties/${propertyId}/extras/${extraId}/values/${extraValueId}`)
}

export const requestListingSubCategory = async (listingCategoryId, page = 1, per_page) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories?page=${page}&per_page=${per_page}&filter[trashed]=with&include=listingsCount`)
}

export const requestUpdateListingSubCategory = async (listingCategoryId, listingSubCategoryId, values) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}`, values)
}

export const requestCreateListingSubCategory = async (listingCategoryId, values) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories`, values)
}

export const requestGetListingSubCategory = async (listingCategoryId, listingSubCategoryId) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}`)
}

export const requestDeleteListingSubCategory = async (listingCategoryId, listingSubCategoryId) => {
    return await ConnectNigeria.delete(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}`)
}

export const requestRestoreListingSubCategory = async (listingCategoryId, listingSubCategoryId) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/restore`)
}

export const requestListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, page = 1, per_page) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties?page=${page}&per_page=${per_page}&filter[trashed]=with`)
}

export const requestUpdateListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, values) => {
    return await ConnectNigeria.put(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}`, values)
}

export const requestCreateListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, values) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties`, values)
}

export const requestGetListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}`)
}

export const requestDeleteListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId) => {
    return await ConnectNigeria.delete(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}`)
}

export const requestRestoreListingSubCategoryProperty = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/restore`)
}

export const requestListingSubCategoryPropertyValue = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, page = 1, per_page) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/values?page=${page}&per_page=${per_page}&filter[trashed]=with`)
}

export const requestUpdateListingSubCategoryPropertyValue = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, listingSubCategoryPropertyValueId, values) => {
    return await ConnectNigeria.put(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/values/${listingSubCategoryPropertyValueId}`, values)
}

export const requestCreateListingSubCategoryPropertyValue = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, values) => {
    return await ConnectNigeria.post(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/values`, values)
}

export const requestGetListingSubCategoryPropertyValue = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, listingSubCategoryPropertyValueId) => {
    return await ConnectNigeria.get(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/values/${listingSubCategoryPropertyValueId}`)
}

export const requestDeleteListingSubCategoryPropertyValue = async (listingCategoryId, listingSubCategoryId, listingSubCategoryPropertyId, listingSubCategoryPropertyValueId) => {
    return await ConnectNigeria.delete(`admin/listing-categories/${listingCategoryId}/sub-categories/${listingSubCategoryId}/properties/${listingSubCategoryPropertyId}/values/${listingSubCategoryPropertyValueId}`)
}

export const requestFetchAllListings = async (name, per_page) => {
    return await ConnectNigeria.get(`/admin/listings?${name ? `filter[name]=${name}&` : ''}per_page=${per_page}`)
 }

 export const requestToggleActiveStateOfListingCategoryProperty = async (listingCategoryId, listingCategoryPropertyId) => {
     return await ConnectNigeria.patch(`/admin/listing-categories/${listingCategoryId}/properties/${listingCategoryPropertyId}/activate/toggle`)
 }

 export const requestToggleMandatoryStateOfListingCategoryProperty = async (listingCategoryId, listingCategoryPropertyId) => {
    return await ConnectNigeria.patch(`/admin/listing-categories/${listingCategoryId}/properties/${listingCategoryPropertyId}/mandatory/toggle`)
}

 export const requestFetchDataTypes = async () => {
    return await ConnectNigeria.get(`/generic/datatypes`);
 }