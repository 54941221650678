import { Redirect } from "react-router";
import { deleteToken } from "../../services/helper";
import { requestLogin } from "../../services/requests/authentication.requests";
import { alertActions } from "../alert/alert.actions";
import { AuthenticationTypes } from "./authentication.types";
import { setTokenUtils } from "./authentication.utils";

export const login = (credentials, redirectCallback = null, history = null) => {
  return async (dispatch) => {
    dispatch({
      type: AuthenticationTypes.LOGIN_REQUEST_PROCESS,
    });

    try {
      const {
        data: { data },
      } = await requestLogin(credentials);
      console.log(data);
      setTokenUtils(data.token);

      dispatch({
        type: AuthenticationTypes.LOGIN_REQUEST_SUCCESS,
        payload: data,
      });

      if (redirectCallback) {
        history.push(redirectCallback);
      } else {
        history.push("/admin/dashboard");
      }
    } catch (error) {
      console.log(error);
      dispatch(alertActions.error(error.response.data.message));
    }
  };
};

export const logout = () => {
  deleteToken();

  <Redirect to="/login" />;
};
