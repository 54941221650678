import { alertTypes } from "./alert.types";

const INITIAL_STATE = {
    error: '',
    success: ''
}
export function alertReducer(state = INITIAL_STATE, { type, payload }) {
    switch (type) {
        case alertTypes.ALERT_SUCCESS:
            return { ...state, success: payload, error: '' }
        case alertTypes.ALERT_ERROR:
            return { ...state, error: payload, success: '' }
        case alertTypes.ALERT_CLEAR:
            return { ...state, error: '', success: '' }
        default:
            return state
    }
}