import { UserTypes } from "./users.types";

const initialState = {
  users: [],
  admins: [],
  loading: false
}

const modelAdminUsers = (admins) => {
  return admins.map(admin => {
      return {
          label: admin.last_name + ' ' + admin.first_name,
          value: admin.id
      }
  })
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case UserTypes.FETCH_ADMIN_USER_PROCESS:
      return { ...state, admins: [], loading: true }
    case UserTypes.FETCH_ADMIN_USER_SUCCESS:
      return { ...state, admins: modelAdminUsers(action?.payload?.data), loading: false }
    default:
      return state;
  }

}

export default usersReducer
