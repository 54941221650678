import { BusinessTypes } from "./business.types"

const INITIAL_STATE = {
    business_categories: [],
    business_category: {},
    loading: false,
    business_sub_categories: [],
    business_sub_category: {},
    stats: {},
    locations: [],
    local_governments: [],
    cities: [],
    states: [],
    kyc_documents: [],
    owners: [],
    business: {},
    businesses: [],
    business_types: [],
    business_hours: [],
    health: ''
}

const modelOwners = (owners) => {
    return owners.map(owner => {
        return {
            label: owner.last_name + ' ' + owner.first_name,
            value: owner.id
        }
    })
}

const modelBusinesses = (businesses) => {
    return businesses.map(business => {
        return {
            label: business.name,
            value: business.id
        }
    })
}

const modelBusinessCategory = (categories) => {
    return categories.map(category => {
        return {
            label: category.name,
            value: category.id
        }
    })
}

const modelBusinessSubCategory = (subCategories) => {
    return subCategories.map(sub_category => {
        return {
            label: sub_category.name,
            value: sub_category.id
        }
    })
}

const modelBusinessTypes = (businessTypes) => {
    return businessTypes.map(business_type => {
        return {
            label: business_type.name,
            value: business_type.id
        }
    })
}

const businessReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case BusinessTypes.FETCH_BUSINESS_CATEGORY_PROCESS:
            return { ...state, business_categories: [], loading: true }
        case BusinessTypes.FETCH_BUSINESS_CATEGORY_SUCCESS:
            return { ...state, business_categories: payload, loading: false }

        case BusinessTypes.FETCH_SINGLE_BUSINESS_CATEGORY_PROCESS:
            return { ...state, business_category: {}, loading: true }
        case BusinessTypes.FETCH_SINGLE_BUSINESS_CATEGORY_SUCCESS:
            return { ...state, business_category: payload, loading: false }

        case BusinessTypes.FETCH_BUSINESS_SUB_CATEGORY_PROCESS:
            return { ...state, business_sub_categories: [], loading: true }
        case BusinessTypes.FETCH_BUSINESS_SUB_CATEGORY_SUCCESS:
            return { ...state, business_sub_categories: payload, loading: false }

        case BusinessTypes.FETCH_SINGLE_SUB_BUSINESS_CATEGORY_PROCESS:
            return { ...state, business_sub_category: {}, loading: true }
        case BusinessTypes.FETCH_SINGLE_SUB_BUSINESS_CATEGORY_SUCCESS:
            return { ...state, business_sub_category: payload, loading: false }

        case BusinessTypes.FETCH_STAT_PROCESS:
            return { ...state, stats: {}, loading: true }
        case BusinessTypes.FETCH_STAT_SUCCESS:
            return { ...state, stats: payload, loading: false }

        case BusinessTypes.FETCH_BUSINESS_LOCATION_PROCESS:
            return { ...state, locations: [], loading: true }
        case BusinessTypes.FETCH_BUSINESS_LOCATION_SUCCESS:
            return { ...state, locations: payload, loading: false }

        case BusinessTypes.FETCH_LOCAL_GOVERNMENT_PROCESS:
            return { ...state, local_governments: [], loading: false }
        case BusinessTypes.FETCH_LOCAL_GOVERNMENT_SUCCESS:
            return { ...state, local_governments: payload, loading: false }

        case BusinessTypes.FETCH_CITY_PROCESS:
            return { ...state, cities: [], loading: false }
        case BusinessTypes.FETCH_CITY_SUCCESS:
            return { ...state, cities: payload, loading: false }

        case BusinessTypes.FETCH_KYC_DOCUMENT_PROCESS:
            return { ...state, kyc_documents: [], loading: true }
        case BusinessTypes.FETCH_KYC_DOCUMENT_SUCCESS:
            return { ...state, kyc_documents: payload, loading: false }

        case BusinessTypes.FETCH_OWNERS_PROCESS:
            return { ...state, owners: [], loading: false }
        case BusinessTypes.FETCH_OWNERS_SUCCESS:
            return { ...state, owners: modelOwners(payload.data), loading: false }

        case BusinessTypes.FETCH_A_BUSINESS_PROCESS:
            return { ...state, business: {}, loading: true }
        case BusinessTypes.FETCH_A_BUSINESS_SUCCESS:
            return { ...state, business: payload, loading: false }

        case BusinessTypes.FETCH_ALL_BUSINESS_PROCESS:
            return { ...state, businesses: [], loading: true }
        case BusinessTypes.FETCH_ALL_BUSINESS_SUCCESS:
            return { ...state, businesses: modelBusinesses(payload?.data), loading: false }
        case BusinessTypes.FETCH_ALL_BUSINESS_RAW_SUCCESS: //this is intended to also use the action creator for a raw and unmodelled form of the endpoint
            return { ...state, businesses: payload, loading: false }

        case BusinessTypes.FETCH_STATE_PROCESS:
            return { ...state, states: [], loading: true }
        case BusinessTypes.FETCH_STATE_SUCCESS:
            return { ...state, states: payload, loading: false }

        case BusinessTypes.FETCH_ALL_BUSINESS_CATEGORY_PROCESS:
            return { ...state, business_categories: [], loading: true }
        case BusinessTypes.FETCH_ALL_BUSINESS_CATEGORY_SUCCESS:
            return { ...state, business_categories: modelBusinessCategory(payload?.data), loading: false }

        case BusinessTypes.FETCH_ALL_BUSINESS_SUBCATEGORY_PROCESS:
            return { ...state, business_sub_categories: [], loading: false }
        case BusinessTypes.FETCH_ALL_BUSINESS_SUBCATEGORY_SUCCESS:
            return { ...state, business_sub_categories: modelBusinessSubCategory(payload?.data), loading: false }

        case BusinessTypes.FETCH_ALL_BUSINESS_TYPES_PROCESS:
            return { ...state, business_types: [], loading: true }
        case BusinessTypes.FETCH_ALL_BUSINESS_TYPES_SUCCESS:
            return { ...state, business_types: modelBusinessTypes(payload?.data), loading: false }

        case BusinessTypes.FETCH_BUSINESS_HOURS_PROCESS:
            return { ...state, business_hours: [], loading: true }
        case BusinessTypes.FETCH_BUSINESS_HOURS_SUCCESS:
            return { ...state, business_hours: payload, loading: false }

        case BusinessTypes.FETCH_BUSINESS_HEALTH_PROCESS:
            return { ...state, health: '', loading: true }
        case BusinessTypes.FETCH_BUSINESS_HEALTH_SUCCESS:
            return { ...state, health: payload, loading: false }
        default:
            return state
    }
}

export default businessReducer