/*eslint-disable*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "../../components/Dropdowns/UserDropdown.js";
import { logout } from "../../redux/authentication/authentication.actions.js";
// import { listingsActions } from "../../redux/listings/listings.action.js";
import {
  deleteListingCategory,
  getListingCategory,
  restoreDeletedListingCategory,
} from "../../redux/listings/listings.action";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state) => state.listingsReducer?.listing_categories
  );

  useEffect(() => {
    const loadListing = async () => {
      await dispatch(getListingCategory());
    };
    loadListing();
  }, [dispatch]);
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
            to="/"
          >
            LOGO
          </Link>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    LOGO
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Admin Layout Pages
            </h6> */}
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Dashboard
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/manage_admins") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/manage_admins"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/manage_admins") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Manage Admin
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/manage_users") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/manage_users"
                >
                  <i
                    className={
                      "fas fa-tv mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/manage_users") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>{" "}
                  Manage Users
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/business") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/business"
                >
                  <i
                    className={
                      "fas fa-store mr-2 text-sm" +
                      (window.location.href.indexOf("/admin/business") !== -1
                        ? "opacity-75"
                        : " text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>
                  Businesses
                </Link>
              </li>
              {data?.map((item, index) => (
                <li key={index} className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block " +
                      (window.location.href.indexOf(
                        `/admin/${item.slug}?id=${item.id}`
                      ) !== -1
                        ? "text-lightBlue-500 hover:text-lightBlue-600"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                    to={`/admin/listing/${item.slug}?id=${item.id}`}
                  >
                    <i
                      className={
                        "fas fa-file-alt mr-2 text-sm " +
                        (window.location.href.indexOf(`/admin/${item.slug}`) !==
                        -1
                          ? " opacity-75"
                          : " text-blueGray-300")
                      }
                    ></i>

                    {item.name}
                  </Link>
                </li>
              ))}
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/claim-business-request"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/claim-business-request"
                >
                  <i
                    className={
                      "fas fa-briefcase mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Claim Business Request
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/listing-category") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/listing-category"
                >
                  <i
                    className={
                      "fas fa-briefcase mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Listing Category
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/category") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/category"
                >
                  <i
                    className={
                      "fas fa-briefcase mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Business Category
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/audit-log") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/audit-log"
                >
                  <i
                    className={
                      "fas fa-history mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Admin Log
                </Link>
              </li>

              {/* <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/user-log") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/user-log"
                >
                  <i className={"fas fa-history mr-2 text-sm" +
                    (window.location.href.indexOf("#") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }> </i>{" "}
                  User Log
                </Link>
              </li> */}

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/christmas-campaign"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/christmas-campaign"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Christmas Campaign
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/cntv") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cntv"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  ></i>
                  CNTV
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/roles") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/roles"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Roles
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/state") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/state"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  State
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/adverts") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/adverts"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Advert Packages
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/subscription-plans"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/subscription-plans"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Featured Sub Plans
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/featured-model") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/featured-model"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Featured Adverts
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/featured-listing-client"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/featured-listing-client"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Featured Listing Client
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/payment-transaction"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/payment-transaction"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Payment Transaction
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/realtime-revenue") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/realtime-revenue"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Realtime Revenue
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/gmv") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/gmv"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  GMV
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/buyer-to-seller") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/buyer-to-seller"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Buyer To Seller
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/market-budget") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/market-budget"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Market Budget
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/monthly-cac") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/monthly-cac"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Monthly CAC
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/cc-eventsTraining"
                    ) !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cc-eventsTraining"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Events and Training
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/club-connect") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/club-connect"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  CC Subscription
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/cc-merchant") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cc-merchant"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  CC Merchant
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/cc-course") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cc-course"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  CC Courses
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/cc-article") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cc-article"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  CC Articles
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/cc-cac") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/cc-cac"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  CC CAC
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/bizmix-event") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/bizmix-event"
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  BizMix Event
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/newsletters") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/newsletters"
                >
                  <i
                    className={
                      "fas fa-newspaper mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  NewsLetter
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/sales-leads") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/sales-leads"
                >
                  <i
                    className={
                      "fas fa-newspaper mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Sales Lead
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/banners") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/banners"
                >
                  <i
                    className={
                      "fas fa-newspaper mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Banners
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/contact-contact") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/constant-contact"
                >
                  <i
                    className={
                      "fas fa-newspaper mr-2 text-sm" +
                      (window.location.href.indexOf("#") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  >
                    {" "}
                  </i>{" "}
                  Constant Contact
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />

            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center" onClick={() => logout()}>
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="#"
                >
                  <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
