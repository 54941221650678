import { StateTypes } from "./state.types"

export const INITIAL_STATE = {
    states: [],
    cities: [],
    city: {},
    local_governments: [],
    local_government: {},
    loading: false
}

const statesReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case StateTypes.FETCH_ALL_STATE_PROCESS:
            return { ...state, loading: true, states: {} }
        case StateTypes.FETCH_ALL_STATE_SUCCESS:
            return { ...state, loading: false, states: payload }

        case StateTypes.FETCH_ALL_CITIES_PROCESS:
            return { ...state, loading: true, cities: [] }
        case StateTypes.FETCH_ALL_CITIES_SUCCESS:
            return { ...state, loading: false, cities: payload }

        case StateTypes.FETCH_A_CITY_PROCESS:
            return { ...state, loading: true, city: {} }
        case StateTypes.FETCH_A_CITY_SUCCESS:
            return { ...state, loading: false, city: payload }

        case StateTypes.FETCH_A_LOCAL_GOVERNMENT_PROCESS:
            return { ...state, loading: true, local_government: {} }
        case StateTypes.FETCH_A_LOCAL_GOVERNMENT_SUCCESS:
            return { ...state, loading: false, local_government: payload }

        case StateTypes.FETCH_ALL_LOCAL_GOVERNMENT_PROCESS:
            return { ...state, loading: true, local_governments: {} }
        case StateTypes.FETCH_ALL_LOCAL_GOVERNMENT_SUCCESS:
            return { ...state, loading: false, local_governments: payload }
        default:
            return state
    }
}

export default statesReducer;