import { AuditLogTypes } from "./audit-log.types"

const INITIAL_STATE = {
    audit_logs: [],
    audit_summary: {},
    loading: false,
    user_logs: []
}

const auditLogReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case AuditLogTypes.FETCH_AUDIT_LOG_PROCESS:
            return { ...state, audit_logs: [], loading: true }
        case AuditLogTypes.FETCH_AUDIT_LOG_SUCCESS:
            return { ...state, audit_logs: payload, loading: false }
        case AuditLogTypes.FETCH_AUDIT_SUMMARY_PROCESS:
            return { ...state, audit_summary: {}, loading: true }
        case AuditLogTypes.FETCH_AUDIT_SUMMARY_SUCCESS:
            return { ...state, audit_summary: payload, loading: false }

        default:
            return state
    }
}

export default auditLogReducer;