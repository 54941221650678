export const alertTypes = {
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_CLEAR: 'ALERT_CLEAR',
}

export const alertLibraryTypes = {
    success: 'success',
    info: 'info',
    error: 'error',
    warning: 'warning',    
};