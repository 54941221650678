import { AdvertTypes } from "./advert.types"

const INITIAL_STATE = {
    packages: [],
    package: {},
    plans: [],
    plan: {},
    subscription_plans: [],
    subscription_plan: {},
    loading: false,
    featured_model: [],
    transactions: [],
    realtime_revenue: [],
    gmv: [],
    buyer_to_seller_kpi: [],
    market_budgets: [],
    monthly_cac: []
}

const advertReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case AdvertTypes.FETCH_ALL_ADVERT_PACKAGE_PROCESS:
            return { ...state, packages: [], loading: true }
        case AdvertTypes.FETCH_ALL_ADVERT_PACKAGE_SUCCESS:
            return { ...state, packages: payload, loading: false }

        case AdvertTypes.FETCH_AN_ADVERT_PACKAGE_PROCESS:
            return { ...state, package: {}, loading: true }
        case AdvertTypes.FETCH_AN_ADVERT_PACKAGE_SUCCESS:
            return { ...state, package: payload, loading: false }

        case AdvertTypes.FETCH_ALL_ADVERT_PLANS_PROCESS:
            return { ...state, plans: [], loading: true }
        case AdvertTypes.FETCH_ALL_ADVERT_PLANS_SUCCESS:
            return { ...state, plans: payload, loading: false }

        case AdvertTypes.FETCH_AN_ADVERT_PLAN_PROCESS:
            return { ...state, plan: {}, loading: true }
        case AdvertTypes.FETCH_AN_ADVERT_PLAN_SUCCESS:
            return { ...state, plan: payload, loading: false }

        case AdvertTypes.FETCH_ALL_SUBSCRIPTION_PLAN_PROCESS:
            return { ...state, subscription_plans: {}, loading: true }
        case AdvertTypes.FETCH_ALL_SUBSCRIPTION_PLAN_SUCCESS:
            return { ...state, subscription_plans: payload, loading: false }

        case AdvertTypes.FETCH_AN_SUBSCRIPTION_PLAN_PROCESS:
            return { ...state, subscription_plan: {}, loading: true }
        case AdvertTypes.FETCH_AN_SUBSCRIPTION_PLAN_SUCCESS:
            return { ...state, subscription_plan: payload, loading: false }

        case AdvertTypes.FETCH_ALL_FEATURED_MODEL_PROCESS:
            return { ...state, featured_model: [], loading: true }
        case AdvertTypes.FETCH_ALL_FEATURED_MODEL_SUCCESS:
            return { ...state, featured_model: payload, loading: false }

        case AdvertTypes.FETCH_ALL_TRANSACTIONS_PROCESS:
            return { ...state, transactions: [], loading: true }
        case AdvertTypes.FETCH_ALL_TRANSACTIONS_SUCCESS:
            return { ...state, transactions: payload, loading: false }

        case AdvertTypes.FETCH_ALL_REALTIME_REVENUE_PROCESS:
            return { ...state, realtime_revenue: [], loading: true }
        case AdvertTypes.FETCH_ALL_REALTIME_REVENUE_SUCCESS:
            return { ...state, realtime_revenue: payload, loading: false }

        case AdvertTypes.FETCH_ALL_GMV_PROCESS:
            return { ...state, gmv: [], loading: true }
        case AdvertTypes.FETCH_ALL_GMV_SUCCESS:
            return { ...state, gmv: payload, loading: false }

        case AdvertTypes.FETCH_ALL_BTS_PROCESS:
            return { ...state, buyer_to_seller_kpi: [], loading: true }
        case AdvertTypes.FETCH_ALL_BTS_SUCCESS:
            return { ...state, buyer_to_seller_kpi: payload, loading: false }

        case AdvertTypes.FETCH_ALL_MARKET_BUDGETS_PROCESS:
            return { ...state, market_budgets: [], loading: true }
        case AdvertTypes.FETCH_ALL_MARKET_BUDGETS_SUCCESS:
            return { ...state, market_budgets: payload, loading: false }

            case AdvertTypes.FETCH_ALL_MONTHLY_CAC_PROCESS:
            return { ...state, monthly_cac: [], loading: true }
        case AdvertTypes.FETCH_ALL_MONTHLY_CAC_SUCCESS:
            return { ...state, monthly_cac: payload, loading: false }
        default:
            return state
    }
}

export default advertReducer