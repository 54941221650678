import { LocationTypes } from "./locations.types"


const listingsReducer = (state={}, action) =>{
  switch (action.type) {
    case LocationTypes.FETCH_STATES:
      return{
      ...state,
      states: action.payload
      }
    
    default:
      return state;
  }
}

export default listingsReducer;