import { BannerTypes } from "./banners.types"

const INITIAL_STATE = {
    banners: [],
    banner: {},
    loading: false
}

const bannerReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case BannerTypes.FETCH_ALL_BANNERS_PROCESS:
            return { ...state, banners: [], loading: true }
        case BannerTypes.FETCH_ALL_BANNERS_SUCCESS:
            return { ...state, banners: payload, loading: false }

        case BannerTypes.FETCH_A_BANNER_PROCESS:
            return { ...state, banner: {}, loading: true }
        case BannerTypes.FETCH_A_BANNER_SUCCESS:
            return { ...state, banner: payload, loading: false }
        default:
            return state
    }
}

export default bannerReducer